import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';




const Banner: React.FC = () => {
    return (
        <section className="w-100">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper: any) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                autoplay = {{ delay: 5000 }}
                speed = {1000}
                loop = {true}
            >
                {
                    [...Array(3).keys()].map((idx) => (
                    <SwiperSlide key={idx}>
                        <div> <img src={"./assets/images/banner"+idx+".avif"}  alt='' style= {{width: '100vw', height: '50vw'}}/> </div>
                    </SwiperSlide>
                    ))
                }
            </Swiper>
        </section>
    )
  };

export default Banner;




