import React, { useState, useEffect, useContext, Dispatch, SetStateAction } from 'react';
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"
import './PracticeFooter.scss';
import MAnswer from '../mAnswer/MAnswer';

interface Props {
  qType: string,
  qId: number,
  setQId: (qId: number) => void,
  qCount: number
  dispatch?: (action: any) => void,
  setModalShow: () => void,
  setOrder: Dispatch<SetStateAction<'ASC' | 'DESC' | 'RANDOM'>>
}



const PracticeFooter: React.FC<Props> = (props) => {
  const {token, tokenData, error}: IAuthContext = useContext(AuthContext)
  const [questionCount, setQuestionCount] = useState<number>(0)



  function resetDispatch() {
    if (props.dispatch !== null && props.dispatch !== undefined) {
        props.dispatch({type: "reset"})
    }
  }


  return (
    <>
    <div id="mn-practice-footer" className="px-3 py-2">
      <div className="mn-left">
        <span className="bg" onClick={() => { resetDispatch() } }>Redo</span>
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#mn-modal-answer">Answer</button>
      </div>

      <div className="mn-right">
        <div className="btn-group" role="group" aria-label="Question Order">
          <button type="button" className="btn btn-primary" onClick={() => { props.setOrder('ASC') } }>ASC</button>
          <button type="button" className="btn btn-primary" onClick={() => { props.setOrder('DESC') } }>DESC</button>
          <button type="button" className="btn btn-primary" onClick={() => { props.setOrder('RANDOM') } }>RANDOM</button>
        </div>
        <div className="btn-group" role="group" aria-label="Prev/Next Question">
          <button type="button" className="btn btn-primary" disabled={props.qId === 1} onClick={() => { props.setQId(props.qId - 1)}}>Prev</button>
          <button type="button" className="btn btn-primary" disabled={props.qId === props.qCount} onClick={() => { props.setQId(props.qId + 1)}}>Next</button>
        </div>


        {/* <div className="bg">
          <span
          className={_questionId === 1 ? "disabled" : ""}
          onClick={_questionId !== 1 && (
            () => {
              props.setQuestionId(_questionId - 1)
              resetDispatch()
            })
          }><PreviousBtn moveSlide={prevSlide}/></span>&nbsp;/&nbsp;
          <span
          className={_questionId === questionCount ? "disabled" : ""}
          onClick={_questionId !== questionCount && (
            () => {
              props.setQuestionId(_questionId + 1)
              resetDispatch()
            }
          )}><NextBtn moveSlide={nextSlide}/></span>
        </div> */}
      </div>

      {/* <PopupFeedback modalIsShow={feedbackIsShow} hideModal={() => { setFeedbackIsShow(false) } } />
      <PopupTested modalIsShow={testedIsShow} hideModal={() => { setTestedIsShow(false) } } /> */}
    </div>
    <MAnswer />
    </>
  );
};

export default PracticeFooter;
