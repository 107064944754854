import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"
import { Trans, withTranslation } from 'react-i18next'
import { PATHS } from '../../../global/Constants';
import NavPractice from './NavPractice';
import NavMock from './NavMock';
import './Nav.scss';
import styled from 'styled-components';
const { v4: uuidv4 } = require('uuid');








const Nav: React.FC = () => {
  const { token, login, logOut }: IAuthContext = useContext(AuthContext)
  const [bgOpacity, setBgOpacity] = useState(0.3);


  const RelativeHeader = styled.header`
    background-color: rgba(255, 255, 255, ${bgOpacity});
  `;
  const RelativeLi = styled.li`
    position: relative;
  `;


  return (
    <RelativeHeader>
      <a className="header-download-m" href="https://apps.apple.com/au/app/cow-pte-exam-practice-tool/id1555990766">
        <img src={`${process.env.PUBLIC_URL}/assets/images/mn_download.avif`} alt="PTE,蛮牛App" />
      </a>
      <nav className="px-5">
        <Link to="/" className="mn-pc-logo"><img src={`${process.env.PUBLIC_URL}/assets/images/mn_logo.avif`} alt="" /></Link>
        <ul className="list-unstyled">
          <RelativeLi className="practice-hover">
            <Link to={`${PATHS.admin.path}${PATHS.practice.path}`} className="px-2"><Trans>Questions</Trans></Link>
            <NavPractice key={uuidv4()} />
          </RelativeLi>
          <li><Link to={`${PATHS.admin.path}${PATHS.admin_predict.path}`} className="px-2"><Trans>Predicts</Trans></Link></li>
          <RelativeLi className="mock-hover" >
            <Link to={`${PATHS.admin.path}${PATHS.admin_mock.path}`} className="px-2"><Trans>Mocks</Trans></Link>
            <NavMock key={uuidv4()} />
          </RelativeLi>
          <li> <Link to={PATHS.course.path} className="px-2"><Trans>Course</Trans></Link> </li>
          <li> <Link to={PATHS.community.path} className="px-2"><Trans>Community</Trans></Link> </li>
          <li> <Link to={PATHS.stream.path} className="px-2"><Trans>Stream</Trans></Link> </li>
          <li> <Link to={PATHS.contact.path} className="px-2"><Trans>Contact</Trans></Link> </li>
          { token ?
          ( <button onClick={() => logOut()}><Trans>Logout</Trans></button> ):
          ( <button onClick={() => login()}><Trans>Login</Trans></button> )
          }
        </ul>
      </nav>
    </RelativeHeader>
  );
};

export default Nav;
