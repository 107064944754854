import React from 'react';

interface Props {
    active: boolean;
    index: number;
  }


const TeacherHeadSubItem: React.FC<Props> = (props) => {
  return (
    <li className={`list-item ${props.active ? "active" : ""}`}>
        <img src={process.env.PUBLIC_URL + "/assets/images/mn_tea_sub_" + props.index + ".avif"} alt=''/>
    </li>
  )
};

export default TeacherHeadSubItem;
