import React from 'react'
import './NavMock.scss'


const NavMock: React.FC = () => {
    return (
        <div id="mk-list" className="mk-list">
            <div className="mk-list-item">
                <h5>Mock Test</h5>
                <p>PTE level quiz</p>
                <p>
                    <a className="isLogin" href="/Exam/levelTest.html">Guaranteed class entrance test</a>
                </p>
                <p>Free mock test paper 1</p>
                <p>Free mock test paper 2</p>
                <p>Free mock test paper 3</p>
            </div>
            <div className="mk-list-item">
                <h5>Self-study classes</h5>
                <p>Course Code</p>
                <p>Remedial class model test</p>
            </div>
            <div className="mk-list-item">
                <h5>Off Work</h5>
                <p id="MoldTestCode">Class Quiz</p>
                <p id="MoldTestCode">Offline Model Test</p>
            </div>
        </div>
    )
}

export default NavMock
