import React from 'react';


const RecordPage: React.FC = () => {
  return (
    <div id="mn-record" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Records</div>
      </div>
    </div>
  )
};

export default RecordPage;
