import React, {useState, useEffect} from 'react';
import Button from '../../general/button/Button';
import Title from '../../general/title/Title';
import TeacherHeadItem from './TeacherHeadItem';
import TeacherHeadSubItem from './TeacherHeadSubItem';
import TeacherItem from './TeacherItem';
import './Teacher.scss';



const dataHead = [
  {
      name: "Shark",
      school: "the Australian National University",
      diploma: "Master's degree",
      des: "Scored a GMAT score of 740 out of 800 (97% in the world), and a PTE reading test score of 84 in the first try.",
      slogan: "Slogan: Practice makes perfect"
  },
  {
      name: "Karita",
      school: "Monash University",
      diploma: "Master's degree",
      des: "PTE all-round academics, with profound teaching skills in accurately grasping the trend of the test.",
      slogan: "Slogan: Step by step"
  },
  {
      name: "Jenny",
      school: "Guangdong University of Foreign Studies",
      diploma: "Master's degree",
      des: "Good at helping students to break through the bottleneck of scores in the shortest time",
      slogan: 'Happy study'
  },
  {
      name: "Yulun",
      school: "Guangdong University of Foreign Studies",
      diploma: "Master's degree",
      des: "Well versed in the skills of speaking, writing and listening.",
      slogan: "Help each other"
  },
  {
      name: "Wendy",
      school: "University of Sydney",
      diploma: "Master's degree",
      des: "Famous PTE academic who has been dealing with PTE for a long time.",
      slogan: "Methods are more important"
  }
]


const dataItem = [
  {
      imgName: "mn_tea_sub_1",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_2",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_3",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_4",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_5",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_6",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_7",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_8",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_9",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_10",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_11",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  },
  {
      imgName: "mn_tea_sub_12",
      des: "首考阅读84分",
      slogan: "刷题+方法=成功!"
  }
]



const Teacher: React.FC = () => {
  const [teacherHeadIdx, setTeacherHeadIdx] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTeacherHeadIdx(teacherHeadIdx => (teacherHeadIdx + 1)%5);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section id="mn-teacher" className="py-5">
      <Title
          title = "About us"
          des = "Top-notch educational research team"
      />

      <div className="container">
          <div className="row">
              <div className="col-md-4 p-0 mb-3">
                  <div className="teacher-heads">
                      <div className="teacher-list">
                          {
                              dataHead.map( (item, idx) => (
                              <TeacherHeadItem
                                  key = {`tea-head-${idx}`}
                                  active = {idx === teacherHeadIdx}
                                  index = {idx+1}
                                  name = {item.name}
                                  school = {item.school}
                                  diploma = {item.diploma}
                                  des = {item.des}
                                  slogan = {item.slogan}
                              />
                              ))
                          }
                      </div>

                      <ul className="teacher-l-list">
                          {
                              [...Array(5).keys()].map((idx) => (
                                  <TeacherHeadSubItem
                                  key = {`tea-head-sub-${idx}`}
                                  active = {idx === teacherHeadIdx}
                                  index={idx+1} />
                              ))
                          }
                      </ul>
                  </div>
              </div>

              <div className="col-md-8 animation-translation-rf an-active p-0 mb-3">
                  <div className="teacher-subs">
                  {
                      dataItem.map((item, idx) => (
                          <TeacherItem
                              key = {`tea-${idx}`}
                              imgName={item.imgName}
                              des={item.des}
                          />
                      ))
                  }
                  </div>
              </div>
          </div>
      </div>
      <Button btns = {["Consult", "Join us"]} />
  </section>
  )
};

export default Teacher;
