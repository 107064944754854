export function getQTypeUpper(questionId: string) {
    var res = questionId.split("-");
    return res[0].toUpperCase();
}

export function getQTypeLower(questionId: string) {
    var res = questionId.split("-");
    return res[0].toLowerCase();
}

export function getQId(questionId: string) {
    var res = questionId.split("-");
    return parseInt(res[1]);
}


export function getAPIQuestionCount(qType: string) {
    return 'https://cowpte.com:8705/api/v1/questions/' + qType.toLowerCase() + '/count';
}

export function getAPIQuestionInfos(qType: string) {
    return 'https://cowpte.com:8705/api/v1/questionInfos/' + qType.toLowerCase();
}

export function getAPIQuestionById(qType: string, qId: number) {
    return 'https://cowpte.com:8705/api/v1/questions/' + qType.toLowerCase() + '/' + qId;
}




export function getOptionAnswers(strings: string[]) {
    const options: string[] = [];
    const answers: string[] = [];

    strings.forEach((str) => {
        if (str.startsWith('<answer>') && str.endsWith('</answer>')) {
            answers.push(str.slice(8, -9));
            options.push(str.slice(8, -9));
        } else {
            options.push(str);
        }
    });

    return { options, answers };
}



export const questionTabEnum = {
    content: "content",
    myPractices: "myPractices",
    allPractices: "allPractices",
    allComments: "allComments",
    analysis: "analysis"
}