import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"
import PopupScore from '../popupScore/PopupScore';


interface Props {
  slug: string
}

const MyPractices: React.FC<Props> = (props) => {
  const {token, tokenData, error}: IAuthContext = useContext(AuthContext)


  const [scoreIsShow, setScoreIsShow] = useState<boolean>(false)
  const [attempts, setAttempts] = useState([{
    "timestamp":"loading",
    "textAnswer":"loading",
    "id":"loading",
    "username": "",
    "answerOption": "",
    audioRecoding: ""
  }])
  const [questionType, setQuestionType] = useState<string>("");
  const [questionId, setQuestionId] = useState<string>("");
  const [currentAudio, setCurrentAudio] = useState('');
  const [currentTextAnswer, setCurrentTextAnswer] = useState('');
  const [currentSingleChoiceAnswer, setCurrentSingleChoiceAnswer] = useState('');

  const audioQuestion: Array<String> = ['ra', 'di', 'rl', 'rs', 'asq'];
  const textQuestion: Array<String> = ['swt', 'we', 'sst', 'wfd'];
  const singleChoiceQuestion: Array<String> = ['rmcs', 'hcs', 'mcs', 'smw'];



  useEffect(() => {
    setQuestionType(props.slug.split("-")[0])
    setQuestionId(props.slug.split("-")[1])
  }, [props.slug])


  async function getTextAttempt() {
    const userID = sessionStorage.getItem("userID")
    const foo = props.slug.split("-")
    const qID = foo[0]
    try {
      const url = "https://cowpte.com:8701/api/v1/answer/list/user/${userID}/type/${qID}/id/${questionId}"
      const response = await fetch(url, {
        headers: {  Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setAttempts(data.object)
    } catch (e) { console.error(e) }
  }


  async function getAudioAttempt() {
    const userId = sessionStorage.getItem("userID")
    try {
      const url = "https://cowpte.com:8705/api/v1/answer/list/user/${userId}/type/${questionType}/id/${questionId}"
      const response = await fetch(url, {
        headers: {  Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setAttempts(data.object)

    } catch (e) { console.log(e)  }
  }

  async function getSingleChoiceAttempt() {
    const userId = sessionStorage.getItem("userID")
    try {
      const url = "https://cowpte.com:8705/api/v1/answer/list/user/${userId}/type/${questionType}/id/${questionId}"
      const response = await fetch(url, {
        headers: {  Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setAttempts(data.object)

    } catch (e) { console.log(e) }
  }


  // Fetching the attempts data
  if (audioQuestion.includes(questionType)) {
    getAudioAttempt()
  } else if (textQuestion.includes(questionType)) {
    getTextAttempt();
  } else if (singleChoiceQuestion.includes(questionType)) {
    getSingleChoiceAttempt();
  }


  return (
    <div id="mn-question-mypractices" className="p-3">
      <div className='mypractice px-5 py-3'>
        <ul className="mb-3 px-5 py-3">
        {/* {attempts.map(data => {
          return (
          <>
          <li className='mypractice-list'>
            <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user_icon'/>
            <div className='list-tab'>
              <div key={data["id"]}>
                <h5>{data["username"]}</h5> <p className='datetime'>${data["timestamp"]}</p>
                <p className="attempt">
                  {singleChoiceQuestion.includes(questionType) ? data["answerOption"] :
                  textQuestion.includes(questionType) ? data["textAnswer"] : ''}
                </p>
              </div>
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#mn-modal-score"
              onClick={()=>{setScoreIsShow(true);
                setCurrentAudio(data.audioRecoding);
                setCurrentTextAnswer(data["textAnswer"]);
                setCurrentSingleChoiceAnswer(data["answerOption"]);
                console.log("DATAA!!:", data);
                console.log("Audio Recording", data.audioRecoding)}}
              >
                Score
              </button>
            </div>
          </li>
          <br/>
          </>
          )
        })} */}
        </ul>
      </div>
      <PopupScore
      modalIsShow={scoreIsShow}
      audioUrl={currentAudio}
      questionType={questionType}
      textAnswer={currentTextAnswer}
      singleChoiceAnswer={currentSingleChoiceAnswer}
      hideModal ={() =>{setScoreIsShow(false)}}
      />
    </div>
  )
};

export default MyPractices;
