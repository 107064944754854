import React, { useReducer } from 'react';
import { QuestionWrapper } from '../QuestionWrapper';
import { HOCProps, OriginalComponentProps, WriteStateType } from '../utils';



const QuestionContent: React.FC<OriginalComponentProps & HOCProps> = (props) => {

  return (
    <>
    <div className="mn-practice-body">
      { props.questionData && props.questionData.content }
      { !props.questionData &&  <p>Is loading...</p> }
    </div>
      {/* <WriteBox
      state = {props.state}
      dispatch = {props.dispatch}
      reqKey = "textAnswer"
      qType = "swt"
      qId = {props.qId}
      /> */}
    </>
  )
};


const QuestionPage = QuestionWrapper(QuestionContent);



const PracticeWEPage: React.FC = () => {
  const STATES: { [key: string]: WriteStateType } = {
    prepare: {id: -1},
    idle: {id: 0}
  }

  type ActionType =
  | { type: "reset" }
  | { type: "toIdle" }

  const ACTIONS = {
    reset: "reset" as const,
    toIdle: "toIdle" as const
  }

  const reducer = (state: WriteStateType, action: ActionType) => {
    switch (action.type) {
      case ACTIONS.reset:
        return STATES.prepare
      case ACTIONS.toIdle:
        return STATES.idle;
      default:
        throw new Error();
    }
  }

  const initialState = STATES.prepare
  const [state, dispatch] = useReducer(reducer, initialState)


  return (
    <QuestionPage
    qType="we"
    state = {state}
    dispatch = {dispatch}
    />
  )
};

export default PracticeWEPage;
