import React from 'react';



const VocabPage: React.FC = () => {
  return (
    <div id="mn-vocab" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Vocabularies</div>
      </div>
    </div>
  );
};

export default VocabPage;
