import React from 'react';


const CollectionPage: React.FC = () => {
  return (
    <div id="mn-collection" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">My Collections</div>
      </div>
    </div>
  )
};

export default CollectionPage;
