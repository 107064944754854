import React from 'react';



const SettingPage: React.FC = () => {
  return (
    <div id="mn-setting" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Settings</div>
      </div>
    </div>
  )
};

export default SettingPage;
