import React from 'react';
import { Trans } from 'react-i18next';
import {FcConferenceCall, FcCollapse, FcPortraitMode, FcLikePlaceholder, FcMindMap} from 'react-icons/fc'
import './Sidebar.scss';



const Sidebar: React.FC = () => {
  function scrollToTop(){
    document.documentElement.scrollTop = 0
  }

  return (
    <div id="mn-fixed-sidebar" className="text-center">
      <div className="fixed-nav-item">
        <FcLikePlaceholder />
        <p className="item-text"><Trans>Downloads</Trans></p>
      </div>
      <a className="fixed-nav-item" href="https://tutehub.taobao.com" target="_blank" rel="noreferrer">
        <FcPortraitMode />
        <p className="item-text"><Trans>Consult</Trans></p>
      </a>
      <div className="fixed-nav-item">
        <FcMindMap />
        <p className="item-text"><Trans>Study group</Trans></p>
        <img className="service-code" src="./assets/images/serviceWechatQR.avif" alt="PTE,蛮牛PTE,PTE考试,蛮牛PTE名师学霸群" />
      </div>
      <div className="fixed-nav-item">
        <FcConferenceCall />
        <p className="item-text"><Trans>Be a tutor</Trans></p>
      </div>
      <div className="fixed-nav-item">
        <FcCollapse onClick={scrollToTop}/>
      </div>
    </div>
  )
};

export default Sidebar;
