import React from 'react';
import CourseItem from './CourseItem';


const data = [

    {
        title: "All-round course",
        subTitle1: "20 kinds of questions, 100 kinds of question types, comprehensive coverage",
        subTitle2: "Suitable for students with basic skills",
        style: {background: "linear-gradient(#d5e0fc 0%, #9cade8 100%)"}
    },
    {
        title: "Intermediate Class",
        subTitle1: "Explain questions in detail",
        subTitle2: "Suitable for people who want to improve their skills",
        style: {background: "linear-gradient(#D7D3FA 0%, #B5B0EA 100%)"}
    },
    {
        title: "VIP",
        subTitle1: "Flexible, specialized",
        subTitle2: "Best for people with busy schedules",
        style: {background: "linear-gradient(#CDE8F3 0%, #96CFCB 100%)"}
    },
    {
        title: "1V1 Customized",
        subTitle1: "Small class, strong supervision",
        subTitle2: "Suitable for PTE newbies",
        style: {background: "linear-gradient(#FFE1C3 0%, #F7B879 100%)"}
    },
    {
        title: "Micro-Course",
        subTitle1: "Small class, strong supervision",
        subTitle2: "Suitable for PTE newbies",
        style: {background: "linear-gradient(#C9E9F3 0%, #90D7F0 100%)"}
    },
    {
        title: "Premium 1V1",
        subTitle1: "Small class, strong supervision",
        subTitle2: "Suitable for PTE newbies",
        style: {background: "linear-gradient(#BEE3FF 0%, #78BBFD 100%)"}
    }
]


const VIPCourse: React.FC = () => {
  return (
    <div id="mn-vip-courses" className="pb-3">
        <div className="container">
            <div className="row pb-5">
                {
                    data.map((item, idx) => (
                        <CourseItem
                        key = {`vip-${idx}`}
                        title = {item.title}
                        subTitle1 = {item.subTitle1}
                        subTitle2 = {item.subTitle2}
                        style = {item.style}
                        />
                    ))
                }
            </div>
        </div>
    </div>
  )
};

export default VIPCourse;
