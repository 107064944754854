import React from 'react';



const MFeedback: React.FC = () => {
  return (
    <div id="mn-modal-feedback" className="modal fade" tabIndex={-1} aria-labelledby="mn-modal-feedback" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 id="mn-modal-feedback" className="modal-title fs-5">Modal title</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          Feedback
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Save</button>
          </div>
      </div>
    </div>
  </div>
  );
};

export default MFeedback;
