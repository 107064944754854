import React from 'react';
import Banner from './banner/Banner';
import Platform from './platform/Platform';
import Material from './material/Material';
import Product from './product/Product';
import Compare from './compare/Compare';
import Course from './course/Course';
import Teacher from './teacher/Teacher';
import Testimony from './testimony/Testimony';
import Sidebar from '../general/sidebar/Sidebar';
import Footer from '../general/footer/Footer';


const HomePage: React.FC = () => {
  return (
    <>
      <main>
        <Banner />
        <Platform />
        <Material />
        <Compare />
        <Course />
        <Teacher />
        <Product />
        <Testimony />
      </main>
      <Sidebar />
      <Footer />
    </>
  )
};

export default HomePage;
