import React from 'react';



const TutoringPage: React.FC = () => {
  return (
    <div id="mn-tutoring" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Tutoring</div>
      </div>
    </div>
  )
};

export default TutoringPage;
