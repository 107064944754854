import React from 'react';
import './SharingPage.scss';


const SharingPage: React.FC = () => {
  return (
    <div id="mn-sharing" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Sharing</div>
      </div>
    </div>
  )
};

export default SharingPage;
