import React from 'react';
import {Trans} from 'react-i18next'

import {ImYoutube2} from 'react-icons/im'
import {FaFacebook} from 'react-icons/fa'
import {AiFillTaobaoCircle} from 'react-icons/ai'

import './Footer.scss';




const Footer: React.FC = () => {
  return (
  <footer id="mn-footer" className="pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <img src={"./assets/images/mn_logo_white.avif"} alt="" className="mb-3"/>
                        <p>Makes study more enjoyable</p>
                    </div>
                    <div className="col-md-3">
                        <h4>Contact us</h4>
                        <p>Tel: +61 451 261 860</p>
                        <p>Email：noreply@ptetrain.com</p>
                        <p>Address：Canberra，Australia</p>
                    </div>
                    <div className="col-md-3">
                        <h4>Useful links</h4>
                        <p><a href="https://pearsonpte.com/" target="_blank" rel="noreferrer" ><Trans>Officail registration</Trans></a></p>
                        <p><a href="https://immi.homeaffairs.gov.au/help-support/tools/points-calculator" target="_blank" rel="noreferrer" ><Trans>Immigration points</Trans></a></p>
                        <p><a href="https://immi.homeaffairs.gov.au/visas/visa-pricing-estimator" target="_blank" rel="noreferrer" ><Trans>Visa fee calculation</Trans></a></p>
                        <p><a href="https://pearsonpte.com/wp-content/uploads/2019/02/PTE-Academic-Test-Taker-Handbook-Simplified-Chinese.pdf" target="_blank" rel="noreferrer" ><Trans>Candidate handbook</Trans></a></p>
                        <p><a href="ptetrain.com" target="_blank">Consult</a></p>
                    </div>
                    <div className="col-md-3">
                        <h4>Cooperation</h4>
                        <p><a href="ptetrain.com">Business cooperation</a></p>
                        <p><a href="ptetrain.com">Join as tutor</a></p>
                        <p><a href="ptetrain.com">Join the team</a></p>
                    </div>
                </div>
                <hr className="my-3"></hr>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <p>Follow us</p>
                        <a href="https://tutehub.taobao.com/" target="_blank" rel="noreferrer" ><AiFillTaobaoCircle /></a>
                        <a href="https://www.youtube.com/channel/UCTSEzPwITH1HC19cK6_rDEQ" target="_blank" rel="noreferrer" ><ImYoutube2/></a>
                        <a href="https://www.facebook.com/Fengcao-Culture-109633237637604" target="_blank" rel="noreferrer" ><FaFacebook/></a>
                    </div>
                    <div className="col-md-6">
                        <div className="row">

                        </div>
                    </div>
                </div>
                <div className="law">
                    <div className="copy">Copyright by <Trans>COW PTE</Trans>@ {new Date().getFullYear()} </div>
                    <div className="policies">
                        <p className="mr-5"><a href="ptetrain.com"><Trans>Privacy Policy</Trans></a></p>
                        <p className="mr-5"><a href="ptetrain.com"><Trans>Terms of Usage</Trans></a></p>
                        <p className="mr-5"><a href="ptetrain.com"><Trans>Cookies</Trans></a></p>
                    </div>
                </div>
            </div>
        </footer>
  )
};

export default Footer;
