import React from 'react';


interface Props {
  slug: string
}
const QuestionAnalysis: React.FC<Props> = (props) => {
  return (
    <div id="mn-question-analysis">
    </div>
  );
};

export default QuestionAnalysis;
