import React, { useState, useEffect } from 'react';
import PopupScore from '../popupScore/PopupScore';


interface Props {
  slug: string
}
const AllPractices: React.FC<Props> = (props) => {
  const [scoreIsShow, setScoreIsShow] = useState(false)
  const [attempts, setAttempts] = useState([{
    "timestamp":"loading",
    "textAnswer":"loading",
    "id":"loading",
    "username": "",
    "answerOption": "",
    audioRecoding: ""
  }])
  const [questionType, setQuestionType] = useState<string>('');
  const [questionId, setQuestionId] = useState<string>('');
  const [currentAudio, setCurrentAudio] = useState<string>('');
  const [currentTextAnswer, setCurrentTextAnswer] = useState<string>('');

  const audioQuestion: Array<String> = ['ra', 'di', 'rl', 'rs', 'asq'];
  const textQuestion: Array<String> = ['swt', 'we', 'sst', 'wfd'];
  const singleChoiceQuestion: Array<String> = ['rmcs', 'hcs', 'mcs', 'smw'];

  useEffect(() => {
    setQuestionType(props.slug.split("-")[0])
    setQuestionId(props.slug.split("-")[1])
  }, [props.slug])

  async function getTextAttempt() {
    const userID = sessionStorage.getItem("userID")
    const foo = props.slug.split("-")
    const qID = foo[0]
    let user_attempts
    try {
      // user_attempts = await axios.get(`https://cowpte.com:8701/api/v1/answer/list/other/user/${userID}/type/${qID}/id/${questionId}`)
      // user_attempts = user_attempts["data"]["object"]
      // setAttempts(user_attempts)
    } catch (e) { console.error(e) }
  }

  async function getAudioAttempt() {
    let user_attempts;
    let question_attempt;
    const userId = sessionStorage.getItem("userID")
    try {
      // user_attempts = await axios.get(`https://cowpte.com:8701/api/v1/answer/list/other/user/${userId}/type/${questionType}/id/${questionId}`);
      // question_attempt = user_attempts["data"]["object"]
      // setAttempts(question_attempt);
    } catch (e) { console.log(e) }
  }

  async function getSingleChoiceAttempt() {
    let user_attempts;
    let question_attempt;
    const userId = sessionStorage.getItem("userID")
    try {
      // user_attempts = await axios.get(`https://cowpte.com:8701/api/v1/answer/list/other/user/${userId}/type/${questionType}/id/${questionId}`);
      // question_attempt = user_attempts["data"]["object"]
      // setAttempts(question_attempt);
    } catch (e) { console.log(e) }
  }

  // Fetching the attempts data
  if (audioQuestion.includes(questionType)) {
    getAudioAttempt()
  } else if (textQuestion.includes(questionType)) {
    getTextAttempt();
  } else if (singleChoiceQuestion.includes(questionType)) {
    getSingleChoiceAttempt();
  }


  return (
    <div id="mn-all-practices" className="p-3">
      <div className='allpractice px-5 py-3'>
        <ul className="mb-3 px-5 py-3">
          {attempts.map(data =>{
            return(
              <>
              <li className='allpractice-list'>
                <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user_icon'/>
                <div className='list-tab'>
                  <div key={data["id"]}>
                    <h5>{data["username"]}</h5>
                    <p className='datetime'>${data["timestamp"]}</p>
                    <p className="attempt">
                      {singleChoiceQuestion.includes(questionType) ? data["answerOption"] :
                      audioQuestion.includes(questionType) ? data["textAnswer"] : ''}
                    </p>
                  </div>
                  <div id='button'
                  className="btn btn-primary"
                  onClick={()=>{
                    setScoreIsShow(true);
                    setCurrentAudio(data.audioRecoding);
                    setCurrentTextAnswer(data["textAnswer"]);
                    }}>Score</div>
                </div>
              </li>
              <br/>
              </>
              )
          })}
        </ul>
      </div>
            {/* <PopupScore
                modalIsShow={scoreIsShow}
                audioUrl={currentAudio}
                questionType={questionType}
                textAnswer={currentTextAnswer}
                hideModal ={() =>{setScoreIsShow(false)}}
            /> */}
        </div>
  );
};

export default AllPractices;
