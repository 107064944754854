import React from 'react';

interface Props {
  questionType: string,
  audioUrl: string,
  textAnswer: string,
  singleChoiceAnswer: any,
  modalIsShow: boolean,
  hideModal: () => void
}



const PopupScore: React.FC<Props> = (props) => {
  const audioQuestion: Array<String> = ['ra', 'di', 'rl', 'rs', 'asq'];
  const textQuestion: Array<String> = ['swt', 'we', 'sst', 'wfd'];
  const singleChoiceQuestion: Array<String> = ['rmcs', 'hcs', 'mcs', 'smw'];

  return (
    <div id="mn-modal-score" className="modal fade" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">AI Score</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className='body'>
              <div className='group'>
                <h5><strong>User's answer</strong></h5>
                {
                  (() => {
                    if (audioQuestion.includes(props.questionType)) {
                      return <audio controls src={props.audioUrl} />;
                    } else if (textQuestion.includes(props.questionType)) {
                      return <div>{props.textAnswer}</div>;
                    } else if (singleChoiceQuestion.includes(props.questionType)) {
                      return <div>{props.singleChoiceAnswer}</div>;
                    } else {
                      return <div>Unable to display students answer</div>;
                    }
                  })()
                }
                <br/>
                <h5><strong>Score</strong></h5>
                <p>Oral fluency</p>
                <div className='container'>
                  <div className='skills oral-score'>45/90</div>
                </div>
                <p>Pronunciation</p>
                <div className='container'>
                  <div className='skills pronunciation-score'>50/90</div>
                </div>
                <p>Overall</p>
                <div className='container'>
                  <div className='skills overall-score'>55/90</div>
                </div>
                <h5>AI analysis</h5>
                <div className='analysis-rate'>
                  <span className='dot-good rate'>Good</span>
                  <span className='dot-bad rate'>Bad</span>
                  <span className='dot-average rate'>Average</span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupScore;
