import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"
import { AiOutlineClockCircle, AiFillCaretLeft} from 'react-icons/ai'
import { IoChatbubblesOutline } from "react-icons/io5";
import { IoAnalytics } from "react-icons/io5";
import { LuPencil } from "react-icons/lu";
import { RiGroupLine } from "react-icons/ri";
import { RiFeedbackLine } from "react-icons/ri";
import { FaStar } from "react-icons/fa";
import { getAPIQuestionById, getAPIQuestionCount, questionTabEnum } from '../utils';
import './PracticeHeader.scss';
import useSWR from 'swr';
import { fetcher } from '../../../../../global/Constants';
import LoadingSpinner from '../../../../general/loading/LoadingSpinner';
import MFeedback from '../mFeedback/MFeedback';
import MTested from '../mTested/MTested';





interface Props {
  qId: number,
  qType: string,
  qCount: number,
  questionTab: string,
  setQuestionTab: (value: string) => void,
  setModalShow: () => void,
}

const PracticeHeader: React.FC<Props> = (props) => {
  const [seconds, setSeconds] = useState(0)
  const {token, error: tokenError }: IAuthContext = useContext(AuthContext)
  const {data, error: dataError, isLoading} = useSWR([
    getAPIQuestionById(props.qType, props.qId)
], (url) => fetcher(url, token));


  function turnSecondsFormat(seconds: number) {
    return new Date(seconds * 1000).toISOString().substr(11, 8)
  }


  useEffect(() => {
    let interval = setInterval(() => {setSeconds(prevState => prevState+1)}, 1000)
    return () => clearInterval(interval)
  }, [seconds])



  function tabReducer(itemType: string) {
    if (props.questionTab === itemType) { return "active" } else { return ""  }
  }


  return (
    <>
    {isLoading && <LoadingSpinner />}
    <div>
      <div id='mn-practice-header' className='p-3'>
        <div className='mn-left'>
          <div onClick={() => {props.setModalShow()}}>
            <AiFillCaretLeft className='mr-2'/>
            <span className="me-3">{props.qType.toUpperCase()}</span>
            <span>#{props.qId} / {props.qCount}</span>
          </div>

          <div>|</div>
          <div className={tabReducer(questionTabEnum.content)} onClick={() => {props.setQuestionTab(questionTabEnum.content)}}><AiOutlineClockCircle />Question</div>
          <div className={tabReducer(questionTabEnum.myPractices)} onClick={() => {props.setQuestionTab(questionTabEnum.myPractices)}}><LuPencil />My Practices</div>
          <div className={tabReducer(questionTabEnum.allPractices)} onClick={() => {props.setQuestionTab(questionTabEnum.allPractices)}}><RiGroupLine />All Pratices</div>
          <div className={tabReducer(questionTabEnum.allComments)} onClick={() => {props.setQuestionTab(questionTabEnum.allComments)}}><IoChatbubblesOutline />Comments</div>
          <div className={tabReducer(questionTabEnum.analysis)} onClick={() => {props.setQuestionTab(questionTabEnum.analysis)}}><IoAnalytics />Analysis</div>
        </div>
        <div className='mn-right' style={{color:'white'}}>
          <AiOutlineClockCircle />
            &nbsp;Timer:&nbsp;
            <span>{turnSecondsFormat(seconds)}</span>
        </div>
      </div>

      <div id='mn-practice-tags' className='px-3'>
        <div className="mn-left d-flex flex-row">
          <h2>{props.qId}.{data?.[0]?.object.title}</h2>
          <span className='question-level'> {data?.[0]?.object.level} </span>
          <span className='question-src'> {data?.[0]?.object.source} </span>
          <span className='question-status'> {data?.[0]?.object.status} </span>
          {data?.[0]?.object.verified && <span className='question-verify'>VERIFIED</span> }
          {data?.[0]?.object.updated && <span className='question-update'>UPDATED</span> }
          {data?.[0]?.object.add && <span className='question-add'>NEW</span> }
          {Array.from({ length: data?.[0]?.object.frequency+1 || 0 }, (_, index) => (
            <FaStar key={index} className='question-freq'/>
          ))}
        </div>
        <div className="mn-right">
          <button type="button" className="btn btn-primary">Collect</button>
          <button type="button" className="btn btn-primary mx-2" data-bs-toggle="modal" data-bs-target="#mn-modal-tested"> Tested</button>
          <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#mn-modal-feedback">Feedback</button>
        </div>
      </div>
    </div>
    <MTested />
    <MFeedback />
    </>
  );
};

export default PracticeHeader;
