import React, {useEffect} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom'
import AdminSidebarItem from './AdminSidebarItem';
import { data } from './data';
import './AdminSidebar.scss';


const AdminSidebar: React.FC = () => {
  const location = useLocation();

  return (
    <section id="mn-admin-side">
      <svg className="expand" viewBox="0 0 256 512" width="100">
        <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
      </svg>

      <ul className="side-nav pb-5">
        <AdminSidebarItem
          location = {location}
          path = {data[0].path}
          icon = {data[0].icon}
          title = {data[0].title}
        />
        <hr />
        {
          data.slice(1, 5).map((item, idx) => (
            <AdminSidebarItem
            key = {idx}
            location = {location}
            path = {item.path}
            icon = {item.icon}
            title = {item.title}
            />
          ))
        }
        <hr />
        {
          data.slice(5, 8).map((item, idx) => (
            <AdminSidebarItem
              key = {idx}
              location = {location}
              path = {item.path}
              icon = {item.icon}
              title = {item.title}
            />
          ))
        }
        <hr />
        {
          data.slice(8, 11).map((item, idx) => (
            <AdminSidebarItem
              key = {idx}
              location = {location}
              path = {item.path}
              icon = {item.icon}
              title = {item.title}
            />
          ))
        }
        <hr />
        {
          data.slice(11, 14).map((item, idx) => (
            <AdminSidebarItem
              key = {idx}
              location = {location}
              path = {item.path}
              icon = {item.icon}
              title = {item.title}
            />
          ))
        }
        <hr />
        {
          data.slice(14, 16).map((item, idx) => (
            <AdminSidebarItem
             key = {idx}
             location = {location}
             path = {item.path}
             icon = {item.icon}
             title = {item.title}
            />
          ))
        }
        <hr />
        {
          data.slice(16, 19).map((item, idx) => (
            <AdminSidebarItem
              key = {idx}
              location = {location}
              path = {item.path}
              icon = {item.icon}
              title = {item.title}
            />
          ))
        }
      </ul>
    </section>
  )
};

export default AdminSidebar;
