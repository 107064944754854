import React from 'react';
import { PATHS } from '../../../global/Constants';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';



interface Props {
    location: {
      pathname: string;
    };
    path: string;
    icon: React.ReactNode;
    title: string;
  }

const AdminSidebarItem: React.FC<Props> = (props) => {
    function isActive(urlPath: string, tabPath: string) {
        if (urlPath === tabPath) { return true  }

        if (!urlPath.match(tabPath)) { return false; }
        else {
            if (tabPath === PATHS.admin.path) {
                return false;
            } else {
                return true;
            }
        }

    }

  return (
    <li className={isActive(props.location.pathname, props.path)? 'active' : undefined}>
        <Link to={props.path}>
            {props.icon}
            <p><Trans>{props.title}</Trans></p>
        </Link>
    </li>
  )
};

export default AdminSidebarItem;
