import React from 'react';
import './FAQPage.scss'



/* Frequently-asked Questions */
const FAQPage: React.FC = () => {
  return (
    <div id="mn-faq" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Frequently-asked Questions</div>
      </div>
    </div>
  )
};

export default FAQPage;
