import React from 'react';


interface PlatformItemProps {
    url: string;
    img: () => React.ReactNode;
    title: string;
    description: string;
    btnTitle: string;
    iconUrl: string;
    iconUrlH: string;
  }


const PlatformItem: React.FC<PlatformItemProps> = (props) => {
  return (
    <li className="practice-item animation-translation-bt" >
    <a href={props.url}>
      <div className="item-head">
        {props.img()}
        <h4>{props.title}</h4>
      </div>
      <p>{props.description}</p>
      <button>
        {props.btnTitle}
        <img className="static-icon" src={props.iconUrl} alt="" />
        <img className="hover-icon" src={props.iconUrlH} alt="" />
      </button>
    </a>
  </li>
  );
};

export default PlatformItem;
