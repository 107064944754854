import React from 'react';
import { Link } from 'react-router-dom'




interface Props {
    destination: string;
    shortName: string;
    name: string;
    numOfQuestions: number;
    hasAI?: boolean;
}

const PracticeListUnit: React.FC<Props> = (props) => {
    return (
        <Link to={props.destination} className={props.hasAI ? "ai" : ""}>
            <span className="p-1">{props.shortName}</span>
            {props.hasAI &&
                <div> {props.name} <b style={{color:"red"}}> / AI </b>({props.numOfQuestions}) </div>
            }
            {props.shortName === "SST"  && !props.hasAI &&
                <div> {props.name} <b style={{color:"red"}}> / AI </b>({props.numOfQuestions}) </div>
            }
            {props.shortName === "WFD"  && !props.hasAI &&
                <div> {props.name} <b style={{color:"red"}}> / AI </b>({props.numOfQuestions}) </div>
            }

            {!props.hasAI && props.shortName !== "SST" && props.shortName !== "WFD" &&
                <div> {props.name} ({props.numOfQuestions}) </div>
            }
        </Link>
    )
}

export default PracticeListUnit;