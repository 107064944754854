import React, { useState, useEffect, useReducer } from 'react';
import { QuestionWrapper } from '../QuestionWrapper';
import './PracticeRAPage.scss';
import { HOCProps, OriginalComponentProps, SpeakStateType } from '../utils';





const QuestionContent: React.FC<OriginalComponentProps & HOCProps> = (props) => {

  return (
    <>
    <div className="mb-3">
        {/* <AudioIndicator
        state={props.state}
        dispatch = {props.dispatch}
        questionId={props.qId}
        questionType = {"ra"}
        allProps={props}
        /> */}
    </div>
    <div className="mn-practice-body px-5">
        { props.questionData && props.questionData.content }
        { !props.questionData && <p>Is loading...</p>}
    </div>
    </>
  )
};


const QuestionPage = QuestionWrapper(QuestionContent);

const PracticeRAPage: React.FC = () => {
  const [playDuration, setPlayDuration] = useState(5)


  const STATES: { [key: string]: SpeakStateType } = {
    prepare: {id: 0, seconds: 40, currentTime: 40},
    record: {id: 1, seconds: 40, currentTime: 0},
    idle: {id: 2, seconds: -1, currentTime: -41}
  }

  type ActionType =
  | { type: "reset" }
  | { type: "toRecord" }
  | { type: "toIdle" }
  | { type: "increase" }
  | { type: "decrease" };


  const ACTIONS = {
    reset: "reset" as const,
    toRecord: "toRecord" as const,
    toIdle: "toIdle" as const,
    increase: "increase" as const,
    decrease: "decrease" as const
  }

  const reducer = (state: SpeakStateType, action: ActionType) => {
    switch (action.type) {
      case ACTIONS.reset:
        return STATES.prepare
      case ACTIONS.toRecord:
        return STATES.record
      case ACTIONS.toIdle:
        return STATES.idle
      case ACTIONS.increase:
        return {...state, currentTime: state.currentTime+1}
      case ACTIONS.decrease:
        return {...state, currentTime: state.currentTime-1}
      default:
        throw new Error();
    }
  }

  const initialState = STATES.prepare
  const [state, dispatch] = useReducer(reducer, initialState)


  useEffect(() => {
    let intervalRef: any

    // prepare
    if (state.id === 0) {
      if (state.currentTime > 0) {
        intervalRef = setInterval(() => {
          dispatch({type: ACTIONS.decrease})
        }, 1000)
      } else {
        dispatch({type: ACTIONS.toRecord})
      }

    // play
    } else if (state.id === 0) {
      if (state.currentTime > 0) {
        intervalRef = setInterval(() => {
          dispatch({type: ACTIONS.decrease})
        }, 1000)
      } else {
        dispatch({type: ACTIONS.toRecord})
      }

    // record
    } else if (state.id === 1) {
      if (state.currentTime < state.seconds) {
        intervalRef = setInterval(() => {
          dispatch({type: ACTIONS.increase})
        }, 1000)
      } else {
        dispatch({type: ACTIONS.toIdle})
      }

    // complete
    } else {
      clearInterval(intervalRef)
    }
    return () => {clearInterval(intervalRef)}

  }, [state])


  return (
    <QuestionPage
    qType="ra"
    state = {state}
    dispatch = {dispatch}
    />
  )
};

export default PracticeRAPage;
