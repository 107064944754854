import React from 'react';
import './LoadingSpinner.scss';


const LoadingSpinner: React.FC = () => {
  return (
    <div className="mn-ls-overlay">
      <div className="mn-ls"></div>
    </div>
  );
};

export default LoadingSpinner;
