import {FcPortraitMode, FcOnlineSupport, FcDocument, FcReading, FcAddDatabase} from 'react-icons/fc'
import { PATHS } from '../../../global/Constants';


interface NavPracticeSubCategory {
    name: string;
    ai?: React.ReactNode;
    path?: string;
}

interface NavPracticeCategory {
    head: string;
    headImgName: React.ReactNode;
    subs: NavPracticeSubCategory[];
}
export const data: NavPracticeCategory[] = [
    {
        head: "Speaking",
        headImgName: <FcPortraitMode size="2.5em"/>,
        subs: [
            {
                name: "(RA) Read Aloud",
                ai: <strong>AI</strong>,
                path: PATHS.practice_ra.path
            },
            {
                name: "(RS) Repeat Sentence",
                ai: <strong>AI</strong>,
                path: PATHS.practice_rs.path
            },
            {
                name: "(DI) Describe Image",
                ai: <strong>AI</strong>,
                path: PATHS.practice_di.path
            },
            {
                name: "(RL) Re-tell Lecture",
                ai: <strong>AI</strong>,
                path: PATHS.practice_rl.path
            },
            {
                name: "(ASQ) Answer Short Question",
                ai: <strong>AI</strong>,
                path: PATHS.practice_asq.path
            }
        ]
    },
    {
        head: "Writing",
        headImgName: <FcDocument size="2.5em" />,
        subs: [
            {
                name: "(SWT) Summarize written text",
                ai: <strong>AI</strong>,
                path: PATHS.practice_swt.path
            },
            {
                name: "(WE) Write essay",
                ai: <strong>AI</strong>,
                path: PATHS.practice_we.path
            }
        ]
    },
    {
        head: "Reading",
        headImgName: <FcReading size="2.5em"/>,
        subs: [
            {
                name: "(RWFIB) Fill in the Blanks",
                path: PATHS.practice_rwfib.path
            },
            {
                name: "(RMCM) Multiple Choice, Multiple Answer",
                path: PATHS.practice_rmcm.path
            },
            {
                name: "(RO) Reorder Paragraphs",
                path: PATHS.practice_ro.path
            },
            {
                name: "(RFIB) Fill in the Blanks",
                path: PATHS.practice_rfib.path
            },
            {
                name: "(RMCS) Multiple Choice, Single Answer",
                path: PATHS.practice_rmcs.path
            }
        ]
    },
    {
        head: "Listening",
        headImgName: <FcOnlineSupport size="2.5em" />,
        subs: [
            {
                name: "(SST) Summarize Spoken Text",
                ai: <strong>AI</strong>,
                path: PATHS.practice_sst.path
            },
            {
                name: "(MCM) Multiple Choice, Multiple Answer",
                path: PATHS.practice_mcm.path
            },
            {
                name: "(FIB) Fill in the Blanks",
                path: PATHS.practice_fib.path
            },
            {
                name: "(HCS) Highlight Correct Summary",
                path: PATHS.practice_hcs.path
            },
            {
                name: "(MCS) Multiple Choice, Single Answer",
                path: PATHS.practice_mcs.path
            },
            {
                name: "(HIW) Highlight Incorrect Words",
                path: PATHS.practice_hiw.path
            },
            {
                name: "(WFD) Write from Dictation",
                ai: <strong>AI</strong>,
                path: PATHS.practice_wfd.path
            }
        ]
    },
    {
        head: "More Tools",
        headImgName: <FcAddDatabase size="2.5em" />,
        subs: [
            {
                name: "Vocab books",
            },
            {
                name: "Shadowing"
            },
            {
                name: "AI study plan"
            },
            {
                name: "AI score report analysis"
            },
            {
                name: "Templates download"
            },
            {
                name: "Predictions download"
            }
        ]
    }
]