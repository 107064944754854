import React from 'react';
import { HOCProps, OriginalComponentProps } from '../utils';
import { QuestionWrapper } from '../QuestionWrapper';


const QuestionContent: React.FC<OriginalComponentProps & HOCProps> = (props) => {


  return (
    <div>
      {  props.questionData && props.questionData.content }
      { !props.questionData &&  <div>No data</div> }
  </div>
  )
};


const QuestionPage = QuestionWrapper(QuestionContent);


const PracticeRWFIBPage: React.FC = () => {
  return <QuestionPage qType="rwfib" />
};

export default PracticeRWFIBPage;
