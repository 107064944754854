import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import { AuthProvider } from 'react-oauth2-code-pkce';
import ReactGA from 'react-ga';

import {PATHS, authConfig} from './global/Constants';
import Nav from './components/general/nav/Nav';
import HomePage from './components/home/HomePage';
import CourseHomePage from './components/course/CourseHomePage';
import StreamPage from './components/stream/StreamPage';
import CommunityPage from './components/community/CommunityPage';
import AdminPage from './components/admin/AdminPage';
import AdminHomePage from './components/admin/home/AdminHomePage';
import MockPage from './components/admin/mock/MockPage';
import MemoryPage from './components/admin/memory/MemoryPage';
import PracticeRAPage from './components/admin/practice/types/ra/PracticeRAPage';
import PracticeDIPage from './components/admin/practice/types/di/PracticeDIPage';
import PracticeRSPage from './components/admin/practice/types/rs/PracticeRSPage';
import PracticeRLPage from './components/admin/practice/types/rl/PracticeRLPage';
import PracticeASQPage from './components/admin/practice/types/asq/PracticeASQPage';
import PracticeSWTPage from './components/admin/practice/types/swt/PracticeSWTPage';
import PracticeWEPage from './components/admin/practice/types/we/PracticeWEPage';
import PracticeRFIBPage from './components/admin/practice/types/rfib/PracticeRFIBPage';
import PracticeRWFIBPage from './components/admin/practice/types/rwfib/PracticeRWFIBPage';
import PracticeRMCMPage from './components/admin/practice/types/rmcm/PracticeRMCMPage';
import PracticeRMCSPage from './components/admin/practice/types/rmcs/PracticeRMCSPage';
import PracticeROPage from './components/admin/practice/types/ro/PracticeROPage';
import PracticeHIWPage from './components/admin/practice/types/hiw/PracticeHIWPage';
import PracticeHCSPage from './components/admin/practice/types/hcs/PracticeHCSPage';
import PracticeSSTPage from './components/admin/practice/types/sst/PracticeSSTPage';
import PracticeSMWPage from './components/admin/practice/types/smw/PracticeSMWPage';
import PracticeMCMPage from './components/admin/practice/types/mcm/PracticeMCMPage';
import PracticeMCSPage from './components/admin/practice/types/mcs/PracticeMCSPage';
import PracticeWFDPage from './components/admin/practice/types/wfd/PracticeWFDPage';
import PracticeFIBPage from './components/admin/practice/types/fib/PracticeFIBPage';
import PredictPage from './components/admin/predict/PredictPage';
import TutoringPage from './components/admin/tutoring/TutoringPage';
import AnalysisPage from './components/admin/analysis/AnalysisPage';
import PracticeListPage from './components/admin/practice/list/PracticeListPage';
import PracticePage from './components/admin/practice/PracticePage';
import CoursePage from './components/admin/course/CoursePage';
import ResearchPage from './components/admin/research/ResearchPage';
import SharingPage from './components/admin/sharing/SharingPage';
import InfoPage from './components/admin/info/InfoPage';
import VocabPage from './components/admin/vocab/VocabPage';
import CollectionPage from './components/admin/collection/CollectionPage';
import PlanPage from './components/admin/plan/PlanPage';
import RecordPage from './components/admin/record/RecordPage';
import GroupPage from './components/admin/group/GroupPage';
import FAQPage from './components/admin/faq/FAQPage';
import SettingPage from './components/admin/setting/SettingPage';
import CacheProvider from './components/general/cache/CacheContext';
import ContactPage from './components/contact/ContactPage';






const App: React.FC = () => {
  const TRACKING_ID = "UA-254165750-1";
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <AuthProvider authConfig={authConfig}>
      <CacheProvider>
        <HashRouter>
          <Nav />
          <Routes>
            <Route path={PATHS.home.path} element={<HomePage />} />
            <Route path={PATHS.course.path}  element={<CourseHomePage />} />
            <Route path={PATHS.community.path} element={<CommunityPage />} />
            <Route path={PATHS.stream.path} element={<StreamPage />} />
            <Route path={PATHS.contact.path} element={<ContactPage />} />

            <Route path={PATHS.admin.path} element={<AdminPage />}>
              <Route index element={<AdminHomePage />} />

              <Route path={PATHS.practice.name} element={<PracticePage />}>
                <Route index element={<PracticeListPage />}></Route>
                <Route path={PATHS.practice_ra.name} element={<PracticeRAPage />}></Route>
                <Route path={PATHS.practice_di.name} element={<PracticeDIPage />}></Route>
                <Route path={PATHS.practice_rs.name} element={<PracticeRSPage />}></Route>
                <Route path={PATHS.practice_rl.name} element={<PracticeRLPage />}></Route>
                <Route path={PATHS.practice_asq.name} element={<PracticeASQPage />}></Route>
                <Route path={PATHS.practice_swt.name} element={<PracticeSWTPage />}></Route>
                <Route path={PATHS.practice_we.name} element={<PracticeWEPage />}></Route>
                <Route path={PATHS.practice_rfib.name} element={<PracticeRFIBPage />}></Route>
                <Route path={PATHS.practice_rwfib.name} element={<PracticeRWFIBPage />}></Route>
                <Route path={PATHS.practice_rmcm.name} element={<PracticeRMCMPage />}></Route>
                <Route path={PATHS.practice_rmcs.name} element={<PracticeRMCSPage />}></Route>
                <Route path={PATHS.practice_ro.name} element={<PracticeROPage />}></Route>
                <Route path={PATHS.practice_hiw.name} element={<PracticeHIWPage />}></Route>
                <Route path={PATHS.practice_hcs.name} element={<PracticeHCSPage />}></Route>
                <Route path={PATHS.practice_sst.name} element={<PracticeSSTPage />}></Route>
                <Route path={PATHS.practice_smw.name} element={<PracticeSMWPage />}></Route>
                <Route path={PATHS.practice_mcm.name} element={<PracticeMCMPage />}></Route>
                <Route path={PATHS.practice_mcs.name} element={<PracticeMCSPage />}></Route>
                <Route path={PATHS.practice_wfd.name} element={<PracticeWFDPage />}></Route>
                <Route path={PATHS.practice_fib.name} element={<PracticeFIBPage />}></Route>
              </Route>
              <Route path={PATHS.admin_predict.name} element={<PredictPage />} />
              <Route path={PATHS.admin_memory.name} element={<MemoryPage />} />
              <Route path={PATHS.admin_mock.name} element={<MockPage />} />
              <Route path={PATHS.admin_info.name} element={<InfoPage />} />
              <Route path={PATHS.admin_course.name} element={<CoursePage />} />
              <Route path={PATHS.admin_tutoring.name} element={<TutoringPage />} />

              <Route path={PATHS.admin_vocab.name} element={<VocabPage />} />
              <Route path={PATHS.admin_collection.name} element={<CollectionPage />} />
              <Route path={PATHS.admin_plan.name} element={<PlanPage />} />

              <Route path={PATHS.admin_research.name} element={<ResearchPage />} />
              <Route path={PATHS.admin_analysis.name} element={<AnalysisPage />} />
              <Route path={PATHS.admin_record.name} element={<RecordPage />} />

              <Route path={PATHS.admin_group.name} element={<GroupPage />} />
              <Route path={PATHS.admin_sharing.name} element={<SharingPage />} />
              <Route path={PATHS.admin_faq.name} element={<FAQPage />} />
              <Route path={PATHS.admin_setting.name} element={<SettingPage />} />
            </Route>
          </Routes>
        </HashRouter>
      </CacheProvider>
    </AuthProvider>
  );
};

export default App;
