import React from 'react';
import PlatformItem from './PlatformItem';
import './Platform.scss';
import Title from '../../general/title/Title';
import Button from '../../general/button/Button';
import { data } from './data';



const Platform: React.FC = () => {
  return (
    <main>
      <section id="mn-platform" className="py-5">
        <Title title = "AI Question Bank" des = "Questions, courses, tutors to help you in PTE" />
        <div className="container">
            <ul className="mn-platform-list">
                {
                data.map((item, idx) => (
                    <PlatformItem
                    key = {idx}
                    url={item.url}
                    img={item.img}
                    title={item.title}
                    description={item.description}
                    btnTitle={item.btnTitle}
                    iconUrl={item.iconUrl}
                    iconUrlH={item.iconUrlH}
                    />
                ))
                }
            </ul>
        </div>
        <Button btns={["Trial class", "Study group"]}/>
      </section>
    </main>
  )
};

export default Platform;
