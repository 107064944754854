import React from 'react';
import { QuestionWrapper } from '../QuestionWrapper';
import { HOCProps, OriginalComponentProps } from '../utils';


const QuestionContent: React.FC<OriginalComponentProps & HOCProps> = (props) => {

  return (
    <div>
      { props.questionData &&
      <div className="mn-practice-body flex-column">
        <b>{ props.questionData.question }</b>
          { props.questionData.options.map((o, idx) => {
            return (
              <div className="form-check">
                <input className="form-check-input" type="radio" key={idx} id={`option-${idx+1}`} value={o} ></input>
                <label className='form-check-label' id={`option-${idx+1}`} data-value={o}>{o}</label>
              </div>
            )
          })}
      </div>
      }
    </div>
  )
};


const QuestionPage = QuestionWrapper(QuestionContent);

const PracticeMCSPage: React.FC = () => {
  return <QuestionPage qType="mcs" />
};

export default PracticeMCSPage;