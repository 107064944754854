import React from 'react';
import './ResearchPage.scss';


const ResearchPage: React.FC = () => {
  return (
    <div id="mn-research" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Researches</div>
      </div>
    </div>
  )
};

export default ResearchPage;
