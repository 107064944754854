import {FaAssistiveListeningSystems, FaRegLightbulb} from 'react-icons/fa';
import {BiWind} from 'react-icons/bi';
import {BsAlarm} from 'react-icons/bs';
import { PATHS } from '../../../global/Constants';


export const data = [
  {
      url: PATHS.admin_predict.name,
      img: () => {return <BiWind size="4vw" css={`background: linear-gradient(to right top, rgb(105, 118, 206), rgba(105, 118, 206, 0.5));`}/>},
      title: 'Predicts',
      description: 'Get most possible questions to be tested.',
      btnTitle: 'Practice',
      iconUrl: './assets/images/arrow_function_1.avif',
      iconUrlH: './assets/images/arrow_function_h.avif',
  },
  {
      url: PATHS.practice.name,
      img: () => {return <FaAssistiveListeningSystems size="4vw" css={`background: linear-gradient(to right top, rgba(51, 141, 245), rgba(51, 141, 245, 0.5));`}/>},
      title: 'Question bank',
      description: 'AI powered conclusive questions',
      btnTitle: 'Practice',
      iconUrl: './assets/images/arrow_function_2.avif',
      iconUrlH: './assets/images/arrow_function_h.avif',
  },
  {
      url: PATHS.admin_mock.name,
      img: () => {return <BsAlarm size="4vw" css={`background: linear-gradient(to right top, rgba(254, 183, 94, 1), rgba(254, 183, 94, 0.5));`}/>},
      title: 'Mocks',
      description: 'Feel how PTE tests and which level you are now',
      btnTitle: 'Test',
      iconUrl: './assets/images/arrow_function_4.avif',
      iconUrlH: './assets/images/arrow_function_h.avif',
  },
  {
      url: PATHS.admin_course.path,
      img: () => {return <FaRegLightbulb size="4vw" css={`background: linear-gradient(to right top, rgb(44, 174, 219), rgba(44, 174, 219, 0.5));`}/>},
      title: 'Courses',
      description: 'Watch latest PTE videos',
      btnTitle: 'Listen',
      iconUrl: './assets/images/arrow_function_3.avif',
      iconUrlH: './assets/images/arrow_function_h.avif',
  }

]
