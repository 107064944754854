import React from 'react';

interface CourseItemProps {
    title: string;
    subTitle1: string;
    subTitle2: string;
    style?: React.CSSProperties;
  }

const CourseItem: React.FC<CourseItemProps> = (props) => {
  return (
    <div className="col-md-4 my-3">
      <div className="shadow border-0 h-100" style={props.style}>
        <div className="card-body">
          <div className="m-0 mb-3">
            <h3><strong>{props.title}</strong></h3>
          </div>
          <div className="mb-2">{props.subTitle1}</div>
          <div className="mb-5">{props.subTitle2}</div>
          <a href="" className="btn btn-outline-light">
            <h5 className="m-0">Consult</h5>
          </a>
        </div>
      </div>
    </div>
  )
};

export default CourseItem;
