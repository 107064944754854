import React from 'react';
import { Trans } from 'react-i18next';




interface TitleProps {
    title: string;
    des: string;
  }

const Title: React.FC<TitleProps> = (props) => {
  return (
    <div className="container">
        <div className="mn-title" >
            <h2 className="my-0"><Trans>{props.title}</Trans></h2>
            <span className="headline my-3"></span>
            <p css={`color: gray`}><Trans>{props.des}</Trans></p>
        </div>
    </div>
  );
};

export default Title;
