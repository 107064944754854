import React from 'react';
import './Button.scss';


interface ButtonProps {
    btns: string[];
  }

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <div className="mn-btn-list mt-5 mb-3">
    {
        props.btns.map((btn) => (
            <button className="mn-common-advisory-btn">{btn}</button>
        ))
    }
    </div>
  );
};

export default Button;
