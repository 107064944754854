import React from 'react';
import MaterialItem from './MaterialItem';
import Title from '../../general/title/Title';
import './Material.scss';



const data = [
  {
      title: "PTE predicts PDF",
      des: "Update every 2 weeks",
      action: "Download"
  },
  {
      title: "Exam memories",
      des: "Update every 2 weeks",
      action: "Download"
  },
  {
      title: "Writing questions",
      des: "Updated till 2023-01",
      action: "Download"
  },
  {
      title: "Listening questions",
      des: "Updated till 2023-01",
      action: "Download"
  }
]


const Material: React.FC = () => {
  return (
  <section id="mn-material" className="py-5">
    <div className="container">
      <Title title = "Updated study materials" des = "We collected latest exam information for you." />
      <div className="material-list">
        {
          data.map((item, idx) => (
            <MaterialItem
            key = {idx}
            title = {item.title}
            des = {item.des}
            action = {item.action}
            />
            ))
          }
        </div>
      </div>
    </section>
  )
};

export default Material;
