/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */

export const data =[
  {
      id:"user-1",
      username:"User 001",
      comment: "user's comment here"
  },
  {
      id:"user-2",
      username:"User 002",
      comment: "user's comment here"
  },
  {
      id:"user-3",
      username:"User 003",
      comment: "user's comment here"
  }
]