import React, { useState, useEffect } from 'react';
import { QuestionWrapper } from '../QuestionWrapper';
import { HOCProps, OriginalComponentProps } from '../utils';
import { getOptionAnswers } from '../../common/utils';


const QuestionContent: React.FC<OriginalComponentProps & HOCProps> = (props) => {
  const [options, setOptions] = useState<string[]>([]);
  const [answers, setAnswers] = useState<string[]>([]);


  return (
    <div>
      { props.questionData &&
      <div className="mn-practice-body">
        <div className="mn-left">
          { props.questionData.content }
        </div>
        <div className="mn-right">
          <b>{ props.questionData.question }</b>
          { props.questionData.options.map((o, idx) => {
            return (
              <div className="form-check">
                <input className="form-check-input" type="checkbox" key={idx} id={`option-${idx+1}`} value={o} ></input>
                <label className='form-check-label' id={`option-${idx+1}`} data-value={o}>{o}</label>
              </div>
            )
          })}
        </div>
      </div>
      }
    </div>
  )
};


const QuestionPage = QuestionWrapper(QuestionContent);

const PracticeRMCMPage: React.FC = () => {
  return <QuestionPage qType="rmcm" />
};

export default PracticeRMCMPage;