import React from 'react';
import './InfoPage.scss';


const InfoPage: React.FC = () => {
  return (
    <div id="mn-info" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">PTE Infos</div>
      </div>
    </div>
  );
};

export default InfoPage;
