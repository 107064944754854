import React, { useEffect, useState, useReducer } from 'react';
import { QuestionWrapper } from '../QuestionWrapper';
import { HOCProps, OriginalComponentProps, SpeakStateType } from '../utils';


const QuestionContent: React.FC<OriginalComponentProps & HOCProps> = (props) => {

  // set play duration
  useEffect(() => {
    let audio: HTMLAudioElement | null = null;

    if (props.questionData && props.questionData.audioPath) {
      audio = new Audio(props.questionData.audioPath)
      audio.addEventListener("loadedmetadata", () => {
        props.setPlayDuration?.(Math.ceil(audio?.duration ?? 0))
      })
    }
    return () => {
      if (audio != undefined) {
         audio.removeEventListener("loadedmetadata", () => {
          if (audio !== null && audio !== undefined) {
            props.setPlayDuration?.(audio.duration);
          }
        })
      }
    }
  }, [props.questionData])


  return (
    <>
    <div>
      {
        props.questionData && props.questionData.audioPath
        // <AudioPlayer
        // state = {props.state}
        // dispatch = {props.dispatch}
        // audioSrc = {props.questionData.audioPath}
        // />
      }
      { !props.questionData &&  "Is loading..." }
    </div>
    {/* <WriteBox
    state = {props.state}
    reqKey="textAnswer"
    qType = "wfd"
    qId = {props.questionId}
    /> */}
    {/*<Button variant="outline-primary" onClick={() => {setShowTranscript(!showTranscript)}}>Show Transcript</Button>
    <ClickableParagraph show = {showTranscript}>{props.questionData.title}</ClickableParagraph>*/}
    </>
  )
};


const QuestionPage = QuestionWrapper(QuestionContent);


const PracticeWFDPage: React.FC = () => {
  const [playDuration, setPlayDuration] = useState(5)


  const STATES: { [key: string]: SpeakStateType } = {
    prepare: {id: -1, seconds: 3, currentTime: 3},
    play: {id: 0, seconds: playDuration, currentTime: playDuration},
    idle: {id: 1, seconds: -1, currentTime: -1}
  }

  type ActionType =
  | { type: "reset" }
  | { type: "toPlay" }
  | { type: "toIdle" }
  | { type: "increase" }
  | { type: "decrease" };

  const ACTIONS = {
    reset: "reset" as const,
    toPlay: "toPlay" as const,
    toIdle: "toIdle" as const,
    increase: "increase" as const,
    decrease: "decrease" as const
  }

  const reducer = (state: SpeakStateType, action: ActionType) => {
    switch (action.type) {
      case ACTIONS.reset:
        return STATES.prepare
      case ACTIONS.toPlay:
        return STATES.play
      case ACTIONS.toIdle:
        return STATES.idle
      case ACTIONS.increase:
        return {...state, currentTime: state.currentTime+1}
      case ACTIONS.decrease:
        return {...state, currentTime: state.currentTime-1}
      default:
        throw new Error();
    }
  }

  const initialState = STATES.prepare
  const [state, dispatch] = useReducer(reducer, initialState)


  useEffect(() => {
    let intervalRef: any

    // prepare
    if (state.id === -1) {
      if (state.currentTime > 0) {
        intervalRef = setInterval(() => {
          dispatch({type: ACTIONS.decrease})
        }, 1000)
      } else {
        dispatch({type: ACTIONS.toPlay})
      }

    // play
    } else if (state.id === 0) {
      if (state.currentTime > 0) {
        intervalRef = setInterval(() => {
          dispatch({type: ACTIONS.decrease})
        }, 1000)
      } else {
        dispatch({type: ACTIONS.toIdle})
      }

    // complete
    } else {
      clearInterval(intervalRef)
    }
    return () => {clearInterval(intervalRef)}

  }, [state])

  return (
    <QuestionPage
    qType="wfd"
    state = {state}
    dispatch = {dispatch}
    setPlayDuration = {setPlayDuration}
    />
  )
};

export default PracticeWFDPage;
