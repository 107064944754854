import React, { useContext } from 'react';
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"
import PracticeUnit from './PracticeListUnit';
import { PATHS, fetcher } from '../../../../global/Constants';
import './PracticeListPage.scss';
import LoadingSpinner from '../../../general/loading/LoadingSpinner';
import useSWR from "swr";
import { getAPIQuestionCount, getAPIQuestionInfos } from '../common/utils';




const speakData = ["ra", "di", "rl", "rs", "asq"]
const writeData = ["swt", "we"]
const readData = ["rfib", "rmcm", "rmcs", "ro", "rwfib"]
const listenData = ["sst", "mcm", "fib", "hcs", "hiw", "mcs", "smw", "wfd"]




const PracticeListPage: React.FC = () => {
  const {token, error: tokenError }: IAuthContext = useContext(AuthContext)

  const { data, error: dataError, isLoading } = useSWR([
    getAPIQuestionCount('ra'), getAPIQuestionCount('di'), getAPIQuestionCount('rl'), getAPIQuestionCount('rs'), getAPIQuestionCount('asq'),
    getAPIQuestionCount('swt'), getAPIQuestionCount('we'),
    getAPIQuestionCount('rfib'), getAPIQuestionCount('rmcm'), getAPIQuestionCount('rmcs'), getAPIQuestionCount('ro'), getAPIQuestionCount('rwfib'),
    getAPIQuestionCount('sst'), getAPIQuestionCount('mcm'), getAPIQuestionCount('fib'), getAPIQuestionCount('hcs'), getAPIQuestionCount('hiw'), getAPIQuestionCount('mcs'), getAPIQuestionCount('smw'), getAPIQuestionCount('wfd'),
    getAPIQuestionInfos('ra'), getAPIQuestionInfos('di'), getAPIQuestionInfos('rl'), getAPIQuestionInfos('rs'), getAPIQuestionInfos('asq'),
    getAPIQuestionInfos('swt'), getAPIQuestionInfos('we'),
    getAPIQuestionInfos('rfib'), getAPIQuestionInfos('rmcm'), getAPIQuestionInfos('rmcs'), getAPIQuestionInfos('ro'), getAPIQuestionInfos('rwfib'),
    getAPIQuestionInfos('sst'), getAPIQuestionInfos('mcm'), getAPIQuestionInfos('fib'), getAPIQuestionInfos('hcs'), getAPIQuestionInfos('hiw'), getAPIQuestionInfos('mcs'), getAPIQuestionInfos('smw'), getAPIQuestionInfos('wfd'),
], (url) => fetcher(url, token));



  return (
    <>
    {isLoading && <LoadingSpinner />}
    <div id="mn-practice-list" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Practice</div>
          <div className="exercises px-5 py-3">
              <ul className="mb-3 px-5 py-3">
                  <li className="exercises-list">
                      <p>
                          <span>Part 1</span>
                          <img src={process.env.PUBLIC_URL+'/assets/images/speak.avif'} alt="speaking" />
                          <i>Speaking</i>
                      </p>
                      <div className="list-tab">
                          {
                              speakData.map((d, idx) => (
                                  <PracticeUnit
                                  key = {idx}
                                  destination = {PATHS[`practice_${d}`]?.name}
                                  shortName = {data?.[idx+20]?.object.shortName}
                                  name = {data?.[idx+20]?.object.fullName}
                                  numOfQuestions = {data?.[idx]?.object}
                                  hasAI = {data?.[idx+20]?.object.ai}
                                  />
                              ))
                          }
                      </div>
                  </li>
                  <li className="exercises-list" style={{alignItems: "center"}}>
                      <p>
                          <img src={process.env.PUBLIC_URL+'/assets/images/write.avif'} alt="icon_writing" />
                          <i>Writing</i>
                      </p>
                      <div className="list-tab" css={`margin-top: 20px;`}>
                          {
                              writeData.map((d, idx) => (
                                  <PracticeUnit
                                  key = {idx}
                                  destination = {PATHS[`practice_${d}`]?.name}
                                  shortName = {data?.[idx+25]?.object.shortName}
                                  name = {data?.[idx+25]?.object.fullName}
                                  numOfQuestions = {data?.[idx+5]?.object}
                                  hasAI = {data?.[idx+25]?.object.ai}
                                  />
                              ))
                          }
                      </div>
                  </li>
              </ul>
              <ul className="mb-3 px-5 py-3">
                  <li className="exercises-list">
                      <p>
                          <span>Part 2</span>
                          <img src={process.env.PUBLIC_URL+'/assets/images/read.avif'} alt="icon_reading" />
                          <i>Reading</i>
                      </p>
                      <div className="list-tab">
                          {
                              readData.map((d, idx) => (
                                  <PracticeUnit
                                  key = {idx}
                                  destination = {PATHS[`practice_${d}`]?.name}
                                  shortName = {data?.[idx+27]?.object.shortName}
                                  name = {data?.[idx+27]?.object.fullName}
                                  numOfQuestions = {data?.[idx+7]?.object}
                                  hasAI = {data?.[idx+27]?.object.ai}
                                  />
                              ))
                          }
                      </div>
                  </li>
              </ul>
              <ul className="m-0 px-5 py-3">
                  <li className="exercises-list">
                      <p>
                          <span>Part 3</span>
                          <img src={process.env.PUBLIC_URL+'/assets/images/listen.avif'} alt="icon_listening" className="icon_listening-3" />
                          <i>Listening</i>
                      </p>
                      <div className="list-tab">
                          {
                              listenData.map((d, idx) => (
                                  <PracticeUnit
                                  key = {idx}
                                  destination = {PATHS[`practice_${d}`]?.name}
                                  shortName = {data?.[idx+32]?.object.shortName}
                                  name = {data?.[idx+32]?.object.fullName}
                                  numOfQuestions = {data?.[idx+12]?.object}
                                  hasAI = {data?.[idx+32]?.object.ai}
                                  />
                              ))
                          }
                      </div>
                  </li>
              </ul>
          </div>
      </div>
    </div>
    </>
  )
};

export default PracticeListPage;





