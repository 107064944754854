import React from 'react';
import {FaChevronLeft} from 'react-icons/fa'


interface Props {
  onClick: () => void
}

const RightBtn: React.FC<Props> = (props) => {
  return (
    <button className="show-all" onClick={props.onClick}> <FaChevronLeft /> </button>
  );
};

export default RightBtn;
