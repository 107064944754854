import React from 'react';
import './PredictPage.scss';

const PredictPage: React.FC = () => {
  return (
    <div id="mn-predict" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Predicts</div>
      </div>
    </div>
  )
};

export default PredictPage;
