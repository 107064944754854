import React from 'react';



const CoursePage: React.FC = () => {
  return (
    <div id="mn-course" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Courses</div>
      </div>
    </div>
  );
};

export default CoursePage;
