import React, { useState, useEffect, useReducer } from 'react';
import AudioIndicator from '../../common/audioIndicator/AudioIndicator';
import { QuestionWrapper } from '../QuestionWrapper';
import './PracticeDIPage.scss';
import { HOCProps, OriginalComponentProps, SpeakStateType } from '../utils';




const QuestionContent: React.FC<OriginalComponentProps & HOCProps> = (props) => {

  return (
    <div className="mn-practice-body">
      {
        props.questionData && (props.questionData.imagePath !== "") &&
        <img src={props.questionData.imagePath} alt={"di-"+props.qId} />
      }
      {/* {
        !props.questionData || (props.questionData.imagePath === "") &&
        <ImageUploader
        questionType="di"
        questionId={props.qId}
        questionData = {props.questionData} />
      } */}
      { !props.questionData && <div>Is loading...</div> }
      <div className="mb-3">
        {/* <AudioIndicator
        state={props.state}
        dispatch = {props.dispatch}
        questionId={props.questionId}
        questionType = {"di"}
        allProps={props}
        /> */}
      </div>
    </div>
  )
};


const QuestionPage = QuestionWrapper(QuestionContent);


const PracticeDIPage: React.FC = () => {

  const STATES: { [key: string]: SpeakStateType } = {
    prepare: {id: 0, seconds: 40, currentTime: 40},
    record: {id: 1, seconds: 40, currentTime: 0},
    idle: {id: 2, seconds: -1, currentTime: -41}
  }


  type ActionType =
  | { type: "reset" }
  | { type: "toRecord" }
  | { type: "toIdle" }
  | { type: "increase" }
  | { type: "decrease" };


  const ACTIONS = {
    reset: "reset" as const,
    toRecord: "toRecord" as const,
    toIdle: "toIdle" as const,
    increase: "increase" as const,
    decrease: "decrease" as const
  }

  const reducer = (state: SpeakStateType, action: ActionType) => {
    switch (action.type) {
      case ACTIONS.reset:
        return STATES.prepare
      case ACTIONS.toRecord:
        return STATES.record
      case ACTIONS.toIdle:
        return STATES.idle
      case ACTIONS.increase:
        return {...state, currentTime: state.currentTime+1}
      case ACTIONS.decrease:
        return {...state, currentTime: state.currentTime-1}
      default:
        throw new Error();
    }
  }

  const initialState = STATES.prepare
  const [state, dispatch] = useReducer(reducer, initialState)


  useEffect(() => {
    let intervalRef: any

    // prepare
    if (state.id === 0) {
      if (state.currentTime > 0) {
        intervalRef = setInterval(() => {
          dispatch({type: ACTIONS.decrease})
        }, 1000)
      } else {
      }

    // play
    } else if (state.id === 1) {
      if (state.currentTime < state.seconds) {
        intervalRef = setInterval(() => {
          dispatch({type: ACTIONS.decrease})
        }, 1000)
      } else {
        dispatch({type: ACTIONS.toRecord})
      }

    // record
    } else if (state.id === 1) {
      if (state.currentTime < state.seconds) {
        intervalRef = setInterval(() => {
          dispatch({type: ACTIONS.increase})
        }, 1000)
      } else {
        dispatch({type: ACTIONS.toIdle})
      }

    // complete
    } else {
      clearInterval(intervalRef)
    }
    return () => {clearInterval(intervalRef)}

  }, [state])


  return (
    <QuestionPage
    qType="di"
    state = {state}
    dispatch = {dispatch}
    />
  )
};

export default PracticeDIPage;
