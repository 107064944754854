import React, { useEffect, useContext, useState } from 'react';
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"


const CourseHomePage: React.FC = () => {
    const {token, tokenData, error}: IAuthContext = useContext(AuthContext)
    const [apiData, setApiData] = useState(null);


      if (error) {
        return ( <div style={{ color: 'red' }}>An error occurred during authentication: {error}</div> )
      }
      else {
        return (
          <>
            {token ? (
              <>
                <div>
                  <div className="my-5"></div>
                  <h4>Access Token (JWT)</h4>
                  <div>
                    {token}
                  </div>
                </div>
                <div>
                  <h4>Login Information from Access Token (Base64 decoded JWT)</h4>
                  <pre>{JSON.stringify(tokenData, null, 2)}</pre>
                </div>
                <div>
                  <p>API Data: {apiData}</p>
                </div>
              </>
            ) : (
                <div>You are not logged in.</div>
            )}
          </>
        )
      }


};

export default CourseHomePage;
