import React, {useState} from 'react';
import Button from '../../general/button/Button';
import Title from '../../general/title/Title';
import FreeCourse from './FreeCourse';
import VIPCourse from './VIPCourse';
import OpenCourse from './OpenCourse';
import './Course.scss';


const Course: React.FC = () => {
  const [activeTab, setActiveTab] = useState('first');


  return (
    <section id="mn-course" className="py-5">
      <div className="container">
        <Title title = "PTE Courses" des = "The first class of your PTE preparation study, from preparing for the test to reaching the top 8" />

        <div className="row">
          <div className="col-md-2">
            <ul className="nav flex-column nav-pills">
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'first' ? 'active' : ''}`}
                  onClick={() => setActiveTab('first')}
                >
                  Free class
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'second' ? 'active' : ''}`}
                  onClick={() => setActiveTab('second')}
                >
                  VIP class
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'three' ? 'active' : ''}`}
                  onClick={() => setActiveTab('three')}
                >
                  Public class
                </button>
              </li>
            </ul>
          </div>
          <div className="col-md-10">
            <div className="tab-content">
              <div className={`tab-pane ${activeTab === 'first' ? 'active' : ''}`}>
                <FreeCourse />
              </div>
              <div className={`tab-pane ${activeTab === 'second' ? 'active' : ''}`}>
                <VIPCourse />
              </div>
              <div className={`tab-pane ${activeTab === 'three' ? 'active' : ''}`}>
                <OpenCourse />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button btns={["Register now", "Inquire now"]}/>
    </section>
  )
};

export default Course;
