import React, {useState} from 'react';

interface Props {
    imgName: string;
    des: string;
  }

const TeacherItem: React.FC<Props> = (props) => {
    const [flipped, setFlipped] = useState(false)

    return (
    <div className="sub-item">
        <div className={"card-container" + (flipped ? " flipped" : "")}>
            <div className="front">
                <div className="image-container">
                    <img src={process.env.PUBLIC_URL + "/assets/images/" + props.imgName + ".avif"} alt=''/>
                </div>
            </div>

            <div className="back">
                <p>{props.des}</p>
            </div>
        </div>
    </div>
    )
};

export default TeacherItem;

