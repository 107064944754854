import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import './AdminPage.scss';
import AdminSidebar from './sidebar/AdminSidebar';



const AdminPage: React.FC = () => {

  return (
    <div id="mn-admin-page">
      <main className="p-3 pr-5">
        <AdminSidebar />
        <Outlet />
      </main>
    </div>
  )

};

export default AdminPage;
