import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Title from '../../general/title/Title';
import './Testimony.scss';



const Testimony: React.FC = () => {
  return (
    <section id="mn-testimony" className="py-5">
      <Title title = "Results" des = "Some PTE score reports" />
      <Swiper
        spaceBetween={20}
        slidesPerView={4}
        navigation
        pagination={{ clickable: true  }}
        autoplay = {{ delay: 3000 }}
        speed = {1000}
        loop = {true}
        >
          {
            [...Array(5).keys()].map((idx) => (
              <SwiperSlide key={idx}>
                <img src={process.env.PUBLIC_URL + "/assets/images/pte_"+(idx+1)+".avif"} alt='' className="shadow"/>
              </SwiperSlide>
              ))
          }
      </Swiper>
    </section>
  )
};

export default Testimony;
