import {FcHome, FcDocument, FcCrystalOscillator, FcSimCard, FcSelfie, FcSportsMode, FcSupport, FcStatistics, FcTodoList, FcVoicePresentation, FcWikipedia, FcOnlineSupport, FcScatterPlot, FcTimeline, FcStackOfPhotos, FcGraduationCap, FcFaq, FcElectricity} from 'react-icons/fc'
import { PATHS } from '../../../global/Constants'


export const data = [
    {
        path: PATHS.admin.path,
        icon: <FcHome />,
        title: "Home"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_predict.path}`,
        icon: <FcSportsMode />,
        title: "Predicts"
    },
    {
        path: `${PATHS.admin.path}${PATHS.practice.path}`,
        icon: <FcDocument />,
        title: "Practices"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_memory.path}`,
        icon: <FcSimCard />,
        title: "Memories"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_mock.path}`,
        icon: <FcSelfie />,
        title: "Mocks"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_info.path}`,
        icon: <FcStackOfPhotos />,
        title: "Infos"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_course.path}`,
        icon: <FcGraduationCap />,
        title: "Courses"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_tutoring.path}`,
        icon: <FcOnlineSupport />,
        title: "Tutorings"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_vocab.path}`,
        icon: <FcWikipedia />,
        title: "Vocabularies"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_collection.path}`,
        icon: <FcOnlineSupport />,
        title: "Collections"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_plan.path}`,
        icon: <FcTodoList />,
        title: "Plans"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_research.path}`,
        icon: <FcStatistics />,
        title: "Researches"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_analysis.path}`,
        icon: <FcTimeline />,
        title: "Analysis"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_record.path}`,
        icon: <FcScatterPlot />,
        title: "Records"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_group.path}`,
        icon: <FcElectricity />,
        title: "Groups"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_sharing.path}`,
        icon: <FcVoicePresentation />,
        title: "Sharings"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_faq.path}`,
        icon: <FcFaq />,
        title: "FAQs"
    },
    {
        path: `${PATHS.admin.path}${PATHS.admin_setting.path}`,
        icon: <FcSupport />,
        title: "Settings"
    },
    {
        path: `${PATHS.admin.path}${PATHS.home.path}`,
        icon: <FcCrystalOscillator />,
        title: "Exit"
    }
]