import React from 'react';

interface Props {
    active: boolean;
    index: number;
    name: string;
    school: string;
    diploma: string;
    des: string;
    slogan: string;
  }

const TeacherHeadItem: React.FC<Props> = (props) => {
  return (
    <div className={`list-item ${props.active ? "active" : ""}`}>
        <div className="item-avatar">
            <img src={process.env.PUBLIC_URL + "/assets/images/mn_tea_head_" + props.index + ".avif"} alt=''/>
        </div>
        <div className="context">
            <p className="teacher-name">{props.name}</p>
            <h5 className="label-pc">{props.school}, {props.diploma}</h5>
            <p className="text">{props.des}</p>
            <p className="lilian-text">{props.slogan}</p>
        </div>
    </div>
  )
};

export default TeacherHeadItem;
