import React from 'react';



const PlanPage: React.FC = () => {
  return (
    <div id="mn-plan" className="mn-panelwrap">
      <div className="mn-panel">
        <div className="mn-panel-title p-2">Daily Plan</div>
      </div>
    </div>
  )
};

export default PlanPage;
