import React, { useState, ReactNode } from 'react';


interface PopupWordPropsType {
  modalIsShow: boolean,
  hideModal: () => void,
  word: string
}

const PopupWord: React.FC<PopupWordPropsType> = (props) => {
  return (
    <div id="mn-modal-word" className="modal" style={{ display: props.modalIsShow ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body p-3">{props.word}</div>
        </div>
      </div>
    </div>
  )
}

interface ClickableWordProps {
  strong: boolean,
  word: string
}
const ClickableWord: React.FC<ClickableWordProps> = (props) => {
  const [wordCardIsShow, setWordCardIsShow] = useState(false);

  return (
    <>
    <span onClick={() => {setWordCardIsShow(true)}} className="clickable-word py-1">
      { props.strong && <strong> {props.word} </strong>}
      { !props.strong && props.word }
    </span>
    <PopupWord
      modalIsShow={wordCardIsShow}
      hideModal={() => {setWordCardIsShow(false)}}
      word={props.word}
    />
    </>
  )
}


interface Props {
  show: boolean,
  strong: boolean,
  children: string;
}
const ClickableParagraph: React.FC<Props> = (props) => {
  /*
  [TODO] how to exclude punctuation from each word
  1) split by " " at first
  2) then judge if the last element is a punctuation
  3) [{content: "", word: true}]
  4) concatenate with " "
  */
 function turnToArray(p: string) {
  const regex = (/\b\w+/g);
  const found = p.match(regex);
  var rawArray = p.trim().split(" ");
  return rawArray
}

return (
  <div className="clickable-paragraph" css={`display: ${props.show === false ? 'none' : 'display'}`}>
    {
      turnToArray(props.children).map((w: string, idx: number) => (
        <> <ClickableWord key={idx} word={w} strong={props.strong}/> &nbsp; </>
      ))
    }
  </div>
  )
};

export default ClickableParagraph;
