import React, { useState } from 'react';
import { data } from './data';


interface Props {
  slug: string
}

const AllComments: React.FC<Props> = (props) => {
  const [repliedState, setRepliedState] = useState(false)

  return (
    <div id="mn-question-all-comments">
    <div className='allcomments px-5 py-3'>
      <ul className="mb-3 px-5 py-3">
        {data.map((data, index) => {
          return(
            <>
            <li className='allcomments-list'>
              <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user_icon'/>
              <div className='list-tab'>
                <div key={data.id}>
                  <h5>{data.username}</h5> <p className='datetime'>MM-DD-YYYY time</p>
                  <br/>
                  <p>{data.comment}</p>
                  <div className='button btn btn-primary'>Like</div>|
                  <div id={data.id} className='btn btn-primary' onClick={() =>{setRepliedState(!repliedState)}}>Reply</div>
                  <div className={repliedState === false ? "no-comment" : "comments"}>
                    <li className='replies'>
                      <img src={process.env.PUBLIC_URL+'/assets/images/userIcon2.avif'} alt='user_icon'/>
                      <div className='list-tab'>
                        <h5>{data.username}</h5> <p className='datetime'>MM-DD-YYYY time</p>
                        <br/>
                        <p>{data.comment}</p>
                      </div>
                    </li>
                  </div>
                </div>
              </div>
            </li>
            <br/>
            </>
            )
          })
        }
      </ul>
    </div>
  </div>
  );
};

export default AllComments;
