import React from 'react';
import {FcCheckmark} from 'react-icons/fc'
import Title from '../../general/title/Title';
import './Product.scss'



const Product: React.FC = () => {
  return (
    <section id="mn-product" className="py-5">
      <Title title = "COW PTE - APP" des = "The choice of millions of PTE candidates" />
        <div className="container">
          <div className="row">
            <div className="col-md-5 mb-5">
              <div className="app-left">
                <div className="app-info animation-translation-fr an-active">
                  <p><FcCheckmark /> AI scored</p>
                  <p><FcCheckmark /> High-fequency questions</p>
                  <p><FcCheckmark /> Updated Micro-tutorials</p>
                  <p><FcCheckmark /> Interactive Community</p>
                </div>
                <div className="app-btns mt-5">
                  <img src={process.env.PUBLIC_URL+'/assets/images/apple.avif'} alt="" className="shadow"/>
                  <img src={process.env.PUBLIC_URL+'/assets/images/qr.avif'} alt="" className="shadow" />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-5 animation-translation-rf an-active">
              <div className="app-right">
                <img src={process.env.PUBLIC_URL+'/assets/images/app.avif'} alt="" className="shadow"/>
              </div>
            </div>
          </div>
        </div>
  </section>
  )
};

export default Product;
