/*
 * Created by TUTEHUB.
 * Copyright © TUTEHUB. All rights reserved.
 * ------------------------
 * Non-disclosure Terms
 * ------------------------
 * These codes are TUTEHUB's property, as the developer, such as employee, contractor, and intern, working for TUTEHUB cannot disclose, spread, copy, preserve, sell, and do other activities without the consent of TUTEHUB.
 * Technique Support: jobyme88.com
 */
import { TAuthConfig, TRefreshTokenExpiredEvent } from "react-oauth2-code-pkce"



interface SubPathsType {
  name: string;
  path: string;
}

interface PathsType {
  [key: string]: SubPathsType;
}

export const PATHS: PathsType = {
    home: { name: "/", path: "/" },
    course: { name: "course", path: "/course" },
    community: { name: "community", path: "/community" },
    stream: { name: "stream", path: "/stream"},
    contact: { name: "contact", path: "/contact"},


    admin: { name: "admin", path: "/admin" },
    practice: { name: "practice",  path: "/practice"},
    practice_ra: { name: "ra", path: "/ra" },
    practice_rs: { name: "rs", path: "/rs"  },
    practice_rl: { name: "rl", path: "/rl"  },
    practice_di: { name: "di", path: "/di"  },
    practice_asq: { name: "asq", path: "/asq"  },
    practice_swt: { name: "swt", path: "/swt"  },
    practice_we: { name: "we", path: "/we"  },
    practice_rfib: { name: "rfib", path: "/rfib"  },
    practice_rmcm: { name: "rmcm", path: "/rmcm"  },
    practice_rmcs: { name: "rmcs", path: "/rmcs"  },
    practice_ro: { name: "ro", path: "/ro"  },
    practice_rwfib: { name: "rwfib", path: "/rwfib"  },
    practice_fib: { name: "fib", path: "/fib"  },
    practice_hcs: { name: "hcs", path: "/hcs"  },
    practice_hiw: { name: "hiw", path: "/hiw"  },
    practice_mcm: { name: "mcm", path: "/mcm"  },
    practice_mcs: { name: "mcs", path: "/mcs"  },
    practice_smw: { name: "smw", path: "/smw"  },
    practice_sst: { name: "sst", path: "/sst"  },
    practice_wfd: { name: "wfd", path: "/wfd"  },

    admin_predict: { name: "predict", path: "/predict" },
    admin_memory: { name: "memory", path: "/memory" },
    admin_setting: { name: "setting", path: "/setting" },
    admin_mock: { name: "mock", path: "/mock", },

    admin_info: { name: "info", path: "/info" },
    admin_course: { name: "course", path: "/course" },
    admin_tutoring: { name: "tutoring", path: "/tutoring" },

    admin_vocab: { name: "vocab", path: "/vocab" },
    admin_collection: { name: "collection", path: "/collection" },
    admin_plan: { name: "plan", path: "/plan" },

    admin_research: { name: "research", path: "/research" },
    admin_analysis: { name: "analysis", path: "/analysis" },
    admin_record: { name: "record", path: "/record" },

    admin_group: { name: "group", path: "/group" },
    admin_sharing: { name: "sharing", path: "/sharing" },
    admin_faq: { name: "faq", path: "/faq" },
    admin_exit: { name: 'exit', path: "/exit" },


    uploadAudio: {name:"uploadAudio", path: "/uploadAudio"}




};



export const BASE_URL = "https://cowpte.com:8705/api/v1/"
export const BASE_QUESTION = "https://cowpte.com:8705/api/v1/questions"



export const BASE_URL_AUTH = "https://cowpte.com:8702/api/v1/user/"
// if we need an API list passed from backend
export const API_REGISTER_BY_EMAIL = BASE_URL_AUTH + "register"
export const API_LOGIN_BY_EMAIL = BASE_URL_AUTH + "login-by-email"
export const API_FORGOT_PASSWORD = BASE_URL_AUTH + "forgot-password"
export const API_LOGIN_BY_USERNAME = BASE_URL_AUTH + "login-by-username"
export const API_RESPONSE_LIST = BASE_URL_AUTH + "login-response-list/json"
export const API_RESET_PASSWORD = BASE_URL_AUTH + "complete-reset-password"
export const API_REQUEST_NEW_OTP = BASE_URL_AUTH + "request-new-otp"

export const BASE_URL_ATTEMPT = "https://cowpte.com:8702/api/v1/answer/"

// export const API_SEND_AUDIO = "https://cowpte.com:8701/api/v1/answer/testSendingAudio"
export const API_SEND_ATTEMPT = BASE_URL_ATTEMPT + "attempt"



export const BASE_URL_2 = "http://192.168.20.6:8702/api/v1/questions/"
// API (test)
export const API_ACCOUNT_URL = "http://localhost:8701/api/v1/accounts/"
// export const BASE_URL = "http://localhost:8081/api/v1/"
//export const BASE_URL = "http://192.168.137.1:8181/"

export const BAST_URL_TEST = "https://9scoretrain.com/pte-files/questions/"



/* local */
// export const authConfig: TAuthConfig = {
//   clientId: 'tfgppa1aewse8dr178tj',
//   authorizationEndpoint: 'https://cowpte.com:8702/oauth2/authorize',
//   tokenEndpoint: 'https://cowpte.com:8702/oauth2/token',
//   redirectUri: 'http://localhost:3000/authorized',
//   scope: 'openid profile',
//   preLogin: () => localStorage.setItem('preLoginPath', window.location.pathname),
//   postLogin: () => window.location.replace(localStorage.getItem('preLoginPath') || ''),
//   decodeToken: true,
//   autoLogin: true,
//   onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => event.login(),
// }


/* vps */
export const authConfig: TAuthConfig = {
  clientId: 'hj91f7fa7oywjl3vg3i0',
  authorizationEndpoint: 'https://cowpte.com:8702/oauth2/authorize',
  tokenEndpoint: 'https://cowpte.com:8702/oauth2/token',
  redirectUri: 'https://cowpte.com/authorized',
  scope: 'openid profile',
  preLogin: () => localStorage.setItem('preLoginPath', window.location.pathname),
  postLogin: () => window.location.replace(localStorage.getItem('preLoginPath') || ''),
  decodeToken: true,
  autoLogin: true,
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => event.login(),
}



export interface QuestionInfoProps {
  shortName: string,
  fullName: string,
  section: string,
  prompt: string
}

export const defaultQuestionInfo:  QuestionInfoProps= {
  shortName: "",
  fullName: "",
  section: "",
  prompt: ""
}




export const fetcher = async (urls: string[], token: string) => {
  const requests = urls.map(url =>
    fetch(url, { headers: { Authorization: `Bearer ${token}` }})
  );

  const responses = await Promise.all(requests);

  const responseData = await Promise.all(
    responses.map(async response => {
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    })
  );

  return responseData;
};






