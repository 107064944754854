import React, { useState, useEffect, useContext, ComponentType } from 'react';
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce"
import { getAPIQuestionById, getAPIQuestionCount, getAPIQuestionInfos, getQTypeLower, questionTabEnum } from '../common/utils';
import { fetcher } from '../../../../global/Constants';
import PracticeHeader from '../common/header/PracticeHeader';
import MyPractices from '../common/myPractices/MyPractices';
import AllPractices from '../common/allPractices/AllPractices';
import AllComments from '../common/allComments/AllComments';
import QuestionAnalysis from '../common/qAnalysis/QuestionAnalysis';
import RightBtn from '../common/rightBtn/RightBtn';
import './QuestionWrapper.scss';
import useSWR from 'swr';
import PracticeFooter from '../common/footer/PracticeFooter';
import { HOCProps, OriginalComponentProps } from './utils';




/**
 * Higher order component
 */
export const QuestionWrapper = (OriginalComponent: ComponentType<OriginalComponentProps & HOCProps>)  => {

  const NewComponent: React.FC<HOCProps> = (props) => {

    const [qId, setQId] = useState<number>(1);
    const [listShow, setListShow] = useState(false)
    const [questionTab, setQuestionTab] = useState<string>(questionTabEnum.content)
    const [order, setOrder] = useState<'ASC' | 'DESC' | 'RANDOM'>('ASC');
    const {token, tokenData, error}: IAuthContext = useContext(AuthContext)
    const { data, error: dataError, isLoading } = useSWR([
      getAPIQuestionCount(props.qType),
      getAPIQuestionInfos(props.qType),
      getAPIQuestionById(props.qType, qId)
  ], (url) => fetcher(url, token));


    return (
      <div id={`mn-practice-${props.qType}`} className="mn-practice">
        <div className="mn-practice-panel">
          <PracticeHeader
          qId = {qId}
          qType = {props.qType}
          qCount = {data?.[0].object}
          questionTab = {questionTab}
          setQuestionTab = {setQuestionTab}
          setModalShow={() => { setListShow(true) }}
          />

          {
            questionTab === questionTabEnum.content &&
            <div className="p-3">
              <div className="mb-3"><em><b>{data?.[1]?.object.prompt}</b></em></div>
              <OriginalComponent {...props} qId={qId} questionData={data?.[2]?.object} />
            </div>
          }
          {
            questionTab === questionTabEnum.myPractices &&
            <MyPractices slug={`${props.qType}-${qId}`} />
          }
          {
            questionTab === questionTabEnum.allPractices &&
            <AllPractices slug={`${props.qType}-${qId}`} />
          }
          {
            questionTab === questionTabEnum.allComments &&
            <AllComments slug={`${props.qType}-${qId}`} />
          }
          {
            questionTab === questionTabEnum.analysis &&
            <QuestionAnalysis slug={`${props.qType}-${qId}`} />
          }

          <PracticeFooter
            qType = {props.qType}
            qId = {qId}
            setQId = {setQId}
            qCount = {data?.[0]?.object}
            dispatch={props.dispatch}
            setModalShow={() => { setListShow(true) }}
            setOrder={setOrder}
          />

      </div>

      <RightBtn onClick={() => setListShow(!listShow)} />
      {/* <QuestionListModal
          slug={`${props.questionType}-${questionId}`}
          modalShow={listShow}
          setModalShow={() => { setListShow(false) }}
          questionInfo={questionInfo}
          questionId={questionId}
          setQuestionId={setQuestionId}
          dispatch={props.dispatch}
          order={order}
      /> */}
  </div>
    )
  };

  return NewComponent
}


