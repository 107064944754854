import React from 'react';



const MTested: React.FC = () => {
  return (
    <div id="mn-modal-tested" className="modal fade" tabIndex={-1} aria-labelledby="mn-modal-tested" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 id="mn-modal-tested" className="modal-title">Exam Information</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <h5> Your test information will help us get more accurate preditcions </h5>
              <form>
                <div>
                  <label>Date(required)</label>
                  <input type="date" autoComplete="off" name="" placeholder="Enter content" id='input-time'/>
                </div>
                <div>
                  <label>Location(optional)</label>
                  <input type="text" autoComplete="off" />
                </div>
                <div>
                  <label>Recall(optional)</label>
                  <textarea autoComplete="off" rows={3} placeholder="Enter content" className="el-textarea__inner" css={`min-height: 33px;`}></textarea>
                </div>
              </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MTested;
