import React, { useState, useEffect, useReducer } from 'react';
import { HOCProps, OriginalComponentProps, SpeakStateType } from '../utils';
import { QuestionWrapper } from '../QuestionWrapper';


const QuestionContent: React.FC<OriginalComponentProps & HOCProps> = (props) => {
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);

  // set play duration
  function clearOut(){
    const input_eles = document.getElementsByTagName('input')
    const label_eles = document.getElementsByTagName('label')
    Array.from(input_eles).forEach(input => {
        input.checked = false
    })
    Array.from(label_eles).forEach(label =>{
      label.style.background="white"
    })
  }


  function submitAns(){
    const input_eles = document.getElementsByTagName('input')
    const label_eles = document.getElementsByTagName('label')
    Array.from(input_eles).forEach(input => {
        console.log("each input:", input);
        if (input.checked) {
            console.log("Selected Options as Value:", input.value);
            // setSelectedOptions(input.value);
            Array.from(label_eles).forEach(label =>{
                console.log("each label:", label);
                const answer = label.getAttribute('value')
                // console.log("q data answer (tag access):", props.questionData["answer"]);
                console.log("answer label:", answer);
                // if (answer === props.questionData["answer"]) {
                //     label.style.background="#4beba8"
                // }
            })
        }
    })
  }



  useEffect(() => {
    let audio: HTMLAudioElement | null = null;

    if (props.questionData && props.questionData.audioPath) {
      audio = new Audio(props.questionData.audioPath)
      audio.addEventListener("loadedmetadata", () => {
        props.setPlayDuration?.(Math.ceil(audio?.duration ?? 0))
      })
    }
    return () => {
      if (audio != undefined) {
         audio.removeEventListener("loadedmetadata", () => {
          if (audio !== null && audio !== undefined) {
            props.setPlayDuration?.(audio.duration);
          }
        })
      }
    }
  }, [props.questionData])



  useEffect(() => {
    if (props.questionData) {
        // let options = [...props.questionData.options];
        // for (let i = options.length - 1; i > 0; i--) {
        //     const j = Math.floor(Math.random() * (i + 1));
        //     [options[i], options[j]] = [options[j], options[i]];
        // }
        // setShuffledOptions(options);
    }
  }, [props.questionData]);



  useEffect(() => {
    clearOut()
  }, [props.qId])



  return (
    <div>
      {
        props.questionData ?
        <>
        {/* <AudioPlayer
        state = {props.state}
        dispatch = {props.dispatch}
        audioSrc = {props.questionData.audioPath}
        /> */}
        <br/>
        { shuffledOptions.map((o, idx) => (
          <>
          {/* <input type="radio" key={idx} name="options" id={idx} value={o} onChange={handleRadioChange} ></input> */}
          {/* <label htmlFor={idx} value={o} className="options"> {o} </label><br/> */}
          </>
          )
        )}
        <br/>
        <div className="btns py-2">
          {/* <SubmitBtn attempt={selectedOptions} qType={"smw"} qId={props.questionId} onSubmit={submitAns}></SubmitBtn> */}
        </div>
        </>
        : 'Is loading...'
      }
    </div>
  )
};


const QuestionPage = QuestionWrapper(QuestionContent);




const PracticeSMWPage: React.FC = () => {
  const [playDuration, setPlayDuration] = useState(45)


  const STATES: { [key: string]: SpeakStateType } = {
    prepare: {id: -1, seconds: 3, currentTime: 3},
    play: {id: 0, seconds: playDuration, currentTime: playDuration},
    idle: {id: 1, seconds: -1, currentTime: -1}
  }

  type ActionType =
  | { type: "reset" }
  | { type: "toPlay" }
  | { type: "toIdle" }
  | { type: "increase" }
  | { type: "decrease" };

  const ACTIONS = {
    reset: "reset" as const,
    toPlay: "toPlay" as const,
    toIdle: "toIdle" as const,
    increase: "increase" as const,
    decrease: "decrease" as const
  }

  const reducer = (state: SpeakStateType, action: ActionType) => {
    switch (action.type) {
      case ACTIONS.reset:
        return STATES.prepare
      case ACTIONS.toPlay:
        return STATES.play;
      case ACTIONS.toIdle:
        return STATES.idle
      case ACTIONS.increase:
        return {...state, currentTime: state.currentTime+1}
      case ACTIONS.decrease:
        return {...state, currentTime: state.currentTime-1}
      default:
        throw new Error();
    }
  }

  const initialState = STATES.prepare
  const [state, dispatch] = useReducer(reducer, initialState)


  useEffect(() => {
    let intervalRef: any

    // prepare
    if (state.id === -1) {
      if (state.currentTime > 0) {
        intervalRef = setInterval(() => {
          dispatch({type: ACTIONS.decrease})
        }, 1000)
      } else {
        dispatch({type: ACTIONS.toPlay})
      }

    // play
    } else if (state.id === 0) {
      if (state.currentTime > 0) {
        intervalRef = setInterval(() => {
          dispatch({type: ACTIONS.decrease})
        }, 1000)
      } else {
        dispatch({type: ACTIONS.toIdle
        })
      }

    // complete
    } else {
      clearInterval(intervalRef)
    }
    return () => {clearInterval(intervalRef)}

  }, [state])


  return (
    <QuestionPage
    qType="smw"
    state = {state}
    dispatch = {dispatch}
    setPlayDuration = {setPlayDuration}
    />
  )
};

export default PracticeSMWPage;
