import React from 'react'
import './NavMock.scss'
import { PATHS } from '../../../global/Constants'
import {data} from './data';


const NavPractice: React.FC = () => {
    return (
        <div className="practice-list">
        {
            data.map((section, idx) => (
                <div key={idx} className="practice-list-item">
                    <div className="item-head">{section.headImgName}<span>{section.head}</span></div>
                    {
                        section.subs.map((sub, idx) => (
                            <div key={idx} className="item-content"><a className="isLogin" href={`${PATHS.admin.path}${PATHS.practice.path}${sub.path}`}><span className="ml-5">{sub.name}</span>{sub.ai}</a></div>
                        ))
                    }
                </div>
            ))
        }
    </div>
    )
}

export default NavPractice
