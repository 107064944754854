import React from 'react';



const ContactPage: React.FC = () => {


  return (
    <div className="mn-contact">
      <div className="mn-contact-header">
        <h1>Contact Us</h1>
      </div>
      <div className="mn-contact-sales">
        <div>
          <span>
            <img decoding="async" src="https://www.reckon.com/au/wp-content/uploads/2024/03/contact-us.png" alt="reckon sales and customer service" title="Contact Us" />
          </span>
          <h2><span>Sales and customer service</span></h2>
          <div>
            <p><a href="tel:1300756663"><strong>1300 756 663</strong></a> Mon – Fri: 9am – 6pmReckon One, Reckon One Payroll &amp;</p>
            <p><a href="tel:1800732566"><strong>1800 732 566</strong></a>Mon – Fri: 8.30am – 6pmReckon Accounts Hosted, Reckon Accounts, Reckon Personal, Payroll Premier &amp; general enquiries.</p>
					</div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
