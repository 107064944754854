import React from 'react';
import { QuestionWrapper } from '../QuestionWrapper';
import { HOCProps, OriginalComponentProps } from '../utils';


const QuestionContent: React.FC<OriginalComponentProps & HOCProps> = (props) => {

  return (
    <div>
      { props.questionData && props.questionData.content }
      { !props.questionData &&  <div>No data</div> }
  </div>
  )
};


const QuestionPage = QuestionWrapper(QuestionContent);

const PracticeHIWPage: React.FC = () => {
  return <QuestionPage qType="hiw" />
};

export default PracticeHIWPage;