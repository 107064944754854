import React from 'react';

interface MaterialItemProps {
    title: string;
    des: string;
    action: string;
}

const MaterialItem: React.FC<MaterialItemProps> = (props) => {
  return (
    <div className="material-item shadow">
        <div className="material-item-new">NEW</div>
        <div className="material-item-title">{props.title}</div>
        <div className="material-item-des">{props.des}</div>
        <button className="material-item-btn" data-url="">{props.action}</button>
    </div>
  );
};

export default MaterialItem;
